import React from "react";
import PromosBanner from "../../components/PromosBanner/";
import CategoryServices from "../../components/CategoryServices";
import PromosFeatures from "../../components/Features/Promos";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footer";
import Contact from "../../components/Contact";

const Promos = (props) => {
  const { ln } = props;

  return (
    <div>
      <Navbar ln={ln} />
      <PromosBanner ln={ln} />
      <PromosFeatures ln={ln} />
      <CategoryServices ln={ln} />
      <Contact ln={ln} />
      <Footer ln={ln} />

    </div>
  );
};

export default Promos;
