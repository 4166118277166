import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  banner: {
    backgroundColor: "#c7e0ed",
    padding: 25,
  },
  head1: {
    color: "#056292",
    fontWeight: "bold",
  },
}));
