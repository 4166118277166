import {useState} from "react";
import Doha1 from "../../assets/OUR_PROJECTS/Doha International Book Fair/LOGO.png";
import Doha2 from "../../assets/OUR_PROJECTS/Doha International Book Fair/Picture2.jpg";
import Doha3 from "../../assets/OUR_PROJECTS/Doha International Book Fair/Picture1.jpg";

import Kaz1 from "../../assets/OUR_PROJECTS/Kazadoo Event/Picture1.jpg";
import Kaz2 from "../../assets/OUR_PROJECTS/Kazadoo Event/Picture2.jpg";
import Kaz3 from "../../assets/OUR_PROJECTS/Kazadoo Event/Picture3.jpg";
import Kaz4 from "../../assets/OUR_PROJECTS/Kazadoo Event/Picture4.jpg";

import Magazine1 from "../../assets/OUR_PROJECTS/Magazine/Magazine1.png";
import Magazine2 from "../../assets/OUR_PROJECTS/Magazine/Magazine2.png";
import Magazine3 from "../../assets/OUR_PROJECTS/Magazine/Magazine3.png";
import Magazine4 from "../../assets/OUR_PROJECTS/Magazine/Magazine4.png";

import Promos1 from "../../assets/OUR_PROJECTS/promos_qa/1.jpg";
import Promos2 from "../../assets/OUR_PROJECTS/promos_qa/2.jpg";
import Promos3 from "../../assets/OUR_PROJECTS/promos_qa/3.jpg";

import Alqabas1 from "../../assets/OUR_PROJECTS/Al_qabas/1.jpg";
import Alqabas2 from "../../assets/OUR_PROJECTS/Al_qabas/2.jpg";
import Alqabas3 from "../../assets/OUR_PROJECTS/Al_qabas/3.jpg";

import Tethqar1 from "../../assets/OUR_PROJECTS/Tethqar/1.png";
import Tethqar2 from "../../assets/OUR_PROJECTS/Tethqar/2.png";
import Tethqar3 from "../../assets/OUR_PROJECTS/Tethqar/3.png";

import PariGallery1 from "../../assets/OUR_PROJECTS/parigallery/1.jpg";

import Line from "../../assets/line.png";
import "./projects.css";
import {Button, Carousel, Col, Row} from "react-bootstrap";
import "reactjs-popup/dist/index.css";

import Doc1 from "../../assets/documents/Promos.pdf";
import Doc2 from "../../assets/documents/Alqabas.pdf";
import Doc3 from "../../assets/documents/TETHQAR.pdf";

import MainCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Projects = (props) => {
    const {ln} = props;
    const [mode, setMode] = useState("less");

    const responsive = {
        superLargeDesktop: { // the naming can be any, depends on you.
            breakpoint: {
                max: 4000,
                min: 3000
            },
            items: 5
        },
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024
            },
            items: 3
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464
            },
            items: 2
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 0
            },
            items: 1
        }
    };

    const subCarouselInterval = 1000;
    const mainCarouselInterval = 4000;

    return (
        <div id="projects">
            <div className="container-fluid pb-5 p-1 mb-5 mt-5"
                style={
                    {backgroundColor: "#cfe2e6"}
            }>
                {
                ln == "en" ? (
                    <h1 className="hb1 mb-3 text-center">OUR PROJECTS</h1>
                ) : (
                    <h1 className="hb1 ar1 mb-3 text-center">مشاريعنا
                    </h1>
                )
            }
                <center>
                    <img src={Line}/>
                </center>


                <div className="container">
                    <MainCarousel arrows={false}
                        infinite={true}
                        responsive={responsive}
                        showDots={true}
                        autoPlay={true}
                        autoPlaySpeed={mainCarouselInterval}>
                        <div>
                            <Carousel interval={0}
                                style={
                                    {
                                        height: 320,
                                        width: '98%'
                                    }
                            }>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Promos1}
                                        className="sqrimg"/>
                                </Carousel.Item>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Promos2}
                                        className="sqrimg"/>
                                </Carousel.Item>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Promos3}
                                        className="sqrimg"/>
                                </Carousel.Item>
                            </Carousel>

                            <a href={Doc1}
                                target="_blank"
                                className="btn-view-more">View More</a>

                        </div>
                        <div>
                            <Carousel interval={0}
                                style={
                                    {
                                        height: 320,
                                        width: '98%'
                                    }
                            }>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Alqabas1}
                                        className="sqrimg"/>
                                </Carousel.Item>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Alqabas2}
                                        className="sqrimg"/>
                                </Carousel.Item>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Alqabas3}
                                        className="sqrimg"/>
                                </Carousel.Item>
                            </Carousel>

                            <a href={Doc2}
                                target="_blank"
                                className="btn-view-more">View More</a>

                        </div>
                        <div>
                            <Carousel interval={0}
                                style={
                                    {
                                        height: 320,
                                        width: '98%'
                                    }
                            }>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Tethqar1}
                                        className="sqrimg"/>
                                </Carousel.Item>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Tethqar2}
                                        className="sqrimg"/>
                                </Carousel.Item>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Tethqar3}
                                        className="sqrimg"/>
                                </Carousel.Item>
                            </Carousel>

                            <a href={Doc3}
                                target="_blank"
                                className="btn-view-more">View More</a>

                        </div>
                        <div>
                            <Carousel interval={0}
                                style={
                                    {
                                        height: 320,
                                        width: '98%'
                                    }
                            }>

                                <Carousel.Item>
                                <img src={PariGallery1}
                                        className="sqrimg"/>
                                </Carousel.Item>
                            </Carousel>
                            <a href='https://parigallery.com/   ' target="_blank" className="btn-view-more">View More</a>

                        </div>
                        <div>
                            <Carousel interval={0}
                                style={
                                    {
                                        height: 320,
                                        width: '98%'
                                    }
                            }>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Doha3}
                                        className="sqrimg"/>
                                </Carousel.Item>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Doha1}
                                        className="sqrimg"/>
                                </Carousel.Item>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Doha2}
                                        className="sqrimg"/>
                                </Carousel.Item>
                            </Carousel>

                            <a onClick={(e)=>e.preventDefault()} target="_blank" className="btn-view-more">View More</a>

                        </div>
                        <div>
                            <Carousel interval={0}
                                style={
                                    {
                                        height: 320,
                                        width: '98%'
                                    }
                            }>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Kaz1}
                                        className="sqrimg"/>
                                </Carousel.Item>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Kaz2}
                                        className="sqrimg"/>
                                </Carousel.Item>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Kaz3}
                                        className="sqrimg"/>
                                </Carousel.Item>
                            </Carousel>

                            <a href='https://youtu.be/JzFwqGfYE_E' target="_blank" className="btn-view-more">View More</a>

                        </div>
                        <div>
                            <Carousel interval={0}
                                style={
                                    {
                                        height: 320,
                                        width: '98%'
                                    }
                            }>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Magazine1}
                                        className="sqrimg"/>
                                </Carousel.Item>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Magazine2}
                                        className="sqrimg"/>
                                </Carousel.Item>
                                <Carousel.Item interval={subCarouselInterval}>
                                    <img src={Magazine3}
                                        className="sqrimg"/>
                                </Carousel.Item>
                            </Carousel>

                            <a href='http://digital.advertiqa.com/issue2/' target="_blank" className="btn-view-more">View More</a>

                        </div>
                      

                    </MainCarousel>
                </div>

            </div>

        </div>
    );
};

export default Projects;
