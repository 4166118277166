import "../../Styles/fonts.css";
import SurveyIcons from "../../assets/Social Media/survey.png";
import { Col, Row } from "react-bootstrap";

import Facebook from "../../assets/footer/facebook.png";
import Twitter from "../../assets/footer/twitter.png";
import Instagram from "../../assets/footer/instagram.png";
import Youtube from "../../assets/footer/youtube.png";
import Snapchat from "../../assets/footer/snapchat.png";
import LinkedIn from "../../assets/footer/linkedIn.png";

const Usage = (props) => {
  const { ln } = props;
  return (
    <div className="container">
      {ln == "en" ? (
        <h1 className="hb1 text-center">Social Media Usage in Qatar in 2021</h1>
      ) : (
        <h1 className="ar1 hb1 text-center" dir="rtl">
          إستعمال شبكات التواصل الإجتماعي في قطر عام{" "}
          <span className="en2">2021</span>
        </h1>
      )}
      {ln == "en" ? (
        <div>
          <h6 className="en2 text-center mt-3">
            There were 2.87 million social media users in Qatar in January 2021.
          </h6>
          <h6 className="en2 text-center">
            The number of social media users in Qatar remained unchanged between
            2020 and 2021.
          </h6>
          <h6 className="en2 text-center">
            The number of social media users in Qatar was equivalent to 98.8% of
            the total population in January 2021.
          </h6>
        </div>
      ) : (
        <div>
          <h6 className="text-center mt-3 ar2" dir="rtl">
            بلغ عدد مستخدمي وسائل التواصل الاجتماعي{" "}
            <span className="en2">2.87</span> مليون مستخدم في قطر في يناير{" "}
            <span className="en2">2021 </span>
          </h6>
          <h6 className="ar2 text-center" dir="rtl">
            ظل عدد مستخدمي وسائل التواصل الاجتماعي في قطر دون تغيير بين عامي
            <span className="en2">2020 و 2021</span>
          </h6>
          <h6 className="ar2 text-center" dir="rtl">
            بلغ عدد مستخدمي وسائل التواصل الاجتماعي في قطر %
            <span className="en2">98.8</span> من إجمالي السكان في يناير{" "}
            <span className="en2">2021</span>
          </h6>
        </div>
      )}
      <div className="p-1">
        <center>
          {ln == "en" ? (
            <Row className="mt-5 mb-4">
              <Col xs={12} md={3} lg={3}></Col>
              <Col xs={4} lg={1} md={1}>
                <h6>Facebook</h6>
                <img src={Facebook} width={70} />
                <p className="mt-2">
                  2.30
                  <br />
                  <span>Million Users</span>
                </p>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <h6>Instagram</h6>
                <img src={Instagram} width={70} />
                <p className="mt-2">
                  1.3
                  <br />
                  <span>Million Users</span>
                </p>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <h6>Twitter</h6>
                <img src={Twitter} width={70} />
                <p className="mt-2">
                  0.47
                  <br />
                  <span>Million Users</span>
                </p>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <h6>Snapchat</h6>
                <img src={Snapchat} width={70} />
                <p className="mt-2">
                  0.64
                  <br />
                  <span>Million Users</span>
                </p>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <h6>YouTube</h6>
                <img src={Youtube} width={70} />
                <p className="mt-2">
                  2.48
                  <br />
                  <span>Million Users</span>
                </p>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <h6>LinkedIn</h6>
                <img src={LinkedIn} width={70} />
                <p className="mt-2">
                  0.91
                  <br />
                  <span>Million Users</span>
                </p>
              </Col>
              <Col xs={12} md={3} lg={3}></Col>
            </Row>
          ) : (
            <Row className="mt-5 mb-4">
              <Col xs={12} md={3} lg={3}></Col>
              <Col xs={4} lg={1} md={1}>
                <h6 className="ar1">لينكد إن</h6>
                <img src={LinkedIn} width={70} />

                <p className="mt-2">
                  0.91
                  <br />
                  <span className="ar2">مليون مستخدم</span>
                </p>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <h6 className="ar1"> يوتيوب </h6>
                <img src={Youtube} width={70} />
                <p className="mt-2">
                  2.48
                  <br />
                  <span className="ar2">مليون مستخدم</span>
                </p>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <h6 className="ar1">سناب شات</h6>
                <img src={Snapchat} width={70} />
                <p className="mt-2">
                  0.64
                  <br />
                  <span className="ar2">مليون مستخدم</span>
                </p>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <h6 className="ar1">تويتر</h6>
                <img src={Twitter} width={70} />
                <p className="mt-2">
                  0.47
                  <br />
                  <span className="ar2">مليون مستخدم</span>
                </p>
              </Col>

              <Col xs={4} lg={1} md={1}>
                <h6 className="ar1">انستاغرام</h6>
                <img src={Instagram} width={70} />
                <p className="mt-2">
                  1.3
                  <br />
                  <span className="ar2">مليون مستخدم</span>
                </p>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <h6 className="ar1">فيسبوك</h6>
                <img src={Facebook} width={70} />
                <p className="mt-2">
                  2.30
                  <br />
                  <span className="ar2">مليون مستخدم</span>
                </p>
              </Col>
              <Col xs={12} lg={3} md={3}></Col>
            </Row>
          )}
          {ln == "en" ? (
            <p>
              <b>Source :</b>{" "}
              https://datareportal.com/reports/digital-2021-qatar
            </p>
          ) : (
            <h6 dir="rtl">
              <b>المصدر </b> https://datareportal.com/reports/digital-2021-qatar
            </h6>
          )}
        </center>
      </div>
    </div>
  );
};

export default Usage;
