import {Row, Col} from "react-bootstrap";
import {useState} from "react";
import Logo1 from "../../assets/CLIENTS/01.png";
import Logo2 from "../../assets/CLIENTS/02.png";
import Logo3 from "../../assets/CLIENTS/03.png";
import Logo4 from "../../assets/CLIENTS/04.png";
import Logo5 from "../../assets/CLIENTS/05.png";
import Logo6 from "../../assets/CLIENTS/06.png";
import Logo7 from "../../assets/CLIENTS/07.png";
import Logo8 from "../../assets/CLIENTS/08.png";
import Logo9 from "../../assets/CLIENTS/09.png";
import Logo10 from "../../assets/CLIENTS/10.png";
import Logo11 from "../../assets/CLIENTS/11.png";
import Logo12 from "../../assets/CLIENTS/12.png";
import Logo13 from "../../assets/CLIENTS/13.png";
import Logo14 from "../../assets/CLIENTS/14.png";
import Logo15 from "../../assets/CLIENTS/15.png";
import Logo16 from "../../assets/CLIENTS/16.png";
import Logo17 from "../../assets/CLIENTS/17.png";
import Logo18 from "../../assets/CLIENTS/18.png";
import Logo19 from "../../assets/CLIENTS/19.png";
import Logo20 from "../../assets/CLIENTS/20.png";
import Logo21 from "../../assets/CLIENTS/21.png";
import Logo22 from "../../assets/CLIENTS/22.png";
import Logo23 from "../../assets/CLIENTS/23.png";
import Logo24 from "../../assets/CLIENTS/24.png";
import Logo25 from "../../assets/CLIENTS/25.png";
import Logo26 from "../../assets/CLIENTS/26.png";
import Logo27 from "../../assets/CLIENTS/27.png";
import Logo28 from "../../assets/CLIENTS/28.png";
import Logo29 from "../../assets/CLIENTS/29.png";
import Logo30 from "../../assets/CLIENTS/30.png";
import Logo31 from "../../assets/CLIENTS/31.png";
import Logo32 from "../../assets/CLIENTS/32.png";
import Logo33 from "../../assets/CLIENTS/33.png";
import Logo34 from "../../assets/CLIENTS/34.png";
import Logo35 from "../../assets/CLIENTS/35.png";
import Logo36 from "../../assets/CLIENTS/36.png";
import Logo37 from "../../assets/CLIENTS/37.png";
import Logo38 from "../../assets/CLIENTS/38.png";

import Line from "../../assets/line.png";

const Clients = (props) => {
    const {ln} = props;
    const [view, setView] = useState(0);
    const Album = (props) => {
        const {image} = props;
        return (
            <Col className="p-3">
                <center>
                    <img src={image}
                        height={70}/>
                </center>
            </Col>
        );
    };
    return (
        <div className="container">
            {
            ln == "en" ? (
                <h1 className="hb1 text-center">OUR CLIENTS</h1>
            ) : (
                <h1 className="hb1 ar1 text-center">عملائنا
                </h1>
            )
        }
            <center>
                <img src={Line}/>
            </center>
            <Row>
                <Album image={Logo2}/>
                <Album image={Logo8}/>
                <Album image={Logo11}/>
                <Album image={Logo19}/>
                <Album image={Logo4}/>
                <Album image={Logo18}/>
                <Album image={Logo16}/>
                <Album image={Logo1}/>
                <Album image={Logo3}/>
                <Album image={Logo5}/>
                <Album image={Logo20}/>
                <Album image={Logo6}/>
                <Album image={Logo13}/>
                <Album image={Logo14}/>
                <Album image={Logo10}/>

                <Album image={Logo17}/>
                <Album image={Logo9}/>
                <Album image={Logo12}/>
                <Album image={Logo15}/>
                <Album image={Logo7}/>

                <Album image={Logo21}/>
                <Album image={Logo22}/>
                <Album image={Logo23}/>
                <Album image={Logo24}/>

                <Album image={Logo25}/>
                <Album image={Logo26}/>
                <Album image={Logo27}/>
                <Album image={Logo28}/>
                <Album image={Logo29}/>
                <Album image={Logo30}/>
                <Album image={Logo31}/>
                <Album image={Logo32}/>
                <Album image={Logo33}/>
                <Album image={Logo34}/>
                <Album image={Logo35}/>
                <Album image={Logo36}/>
                <Album image={Logo37}/>
                <Album image={Logo38}/>
       
            </Row>


        </div>
    );
};
export default Clients;
