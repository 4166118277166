import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 0;

export default makeStyles((theme) => ({
  mission: { backgroundColor: "#056292" },
  vision: { backgroundColor: "#96153A" },
  head: {
    color: "#fff",
    fontSize: 30,
  },
  para: { color: "#fff", textAlign: "left" },
}));
