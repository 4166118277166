import { Row, Col } from "react-bootstrap";
import Line from "../../assets/line.png";
import "../../Styles/fonts.css";

const Relations = (props) => {
  const { ln } = props;

  return (
    <div className="container-fluid p-5 mt-5">
      {ln == "en" ? (
        <Row>
          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1">Public Relations</h2>
            <img src={Line} />
            <p className="en2">
              The field of Public Relations (PR) is so huge and vast. It is all
              about that exceptional capability in sending the right message,
              through the right medium, to the right audience. From mega-size
              corporations, to various working bodies, to social media
              influencers, we reach out to influential people who can assist in
              enhancing the company’s reputation and strengthening its
              relationships with key stakeholders.
            </p>
          </Col>

          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1">Public Relations Strategy</h2>
            <img src={Line} />
            <p className="en2">
              Whether it may be for improving your brand reputation or enhancing
              your relationships with key stakeholders, we will develop a
              detailed PR strategy that is based on your goals.
            </p>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1 ar1" dir="rtl">
              إستراتيجية العلاقات العامة
            </h2>
            <img src={Line} style={{ float: "right" }} />
            <br />
            <p dir="rtl" className="ar2">
              قادرون على تطوير إستراتيجية علاقات عامة تفصيلية مبنية على أهدافك
              وتطلعاتك، سواء تعلَّق الأمر بتحسين مكانة علامتك التجارية/هويتك
              المؤسسية أو تعزيز علاقاتك الثنائية مع الشركاء .
            </p>
          </Col>

          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1 ar1" dir="rtl">
              العلاقات العامة
            </h2>
            <img src={Line} style={{ float: "right" }} />
            <br />
            <p dir="rtl" className="ar2">
              مجال العلاقات العامة واسع وضخم، فهو كل ما يتعلق بالقدرة
              الإستثنائية على إرسال الرسالة الصحيحةبالوسائط والطرق الصحيحة إلى
              الفئة المجتمعية الصحيحة. فمن المؤسسات الضخمة مروراً بكل الجهات
              العاملة إلى مؤثري شبكات التواصل الإجتماعي، نحن قادرون على الوصول
              والتو اصل مع الشخصيات المؤثرة القادرة على التحسين والإرتقاء بسمعة
              ومكانة أي جهة عاملة والعمل على تعزيز جسور التواصل وعلاقاتها
              الثنائية مع الشركاء والمساهمين.
            </p>
          </Col>
        </Row>
      )}

      {ln == "en" ? (
        <Row>
          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1">Execution Action Plan</h2>
            <img src={Line} />
            <p className="en2">
              We will work with you on an action plan that will serve as a
              roadmap to executing the PR strategy. The action plan will include
              ideas for potential events and campaigns.
            </p>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col></Col>
          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1 ar1" dir="rtl">
              خطة عمل التنفيذ
            </h2>
            <img src={Line} style={{ float: "right" }} />
            <br />
            <p dir="rtl" className="ar2">
              سنعمل معك لوضع خطة عمل التنفيذ، والتي ستكون بمثابة خطة طريق لإتمام
              مراحل إستراتيجية العلاقات العامة المحددة. تشمل خطة العمل الأفكار
              والمبادرات المتعلقة بالفعاليات والحملات الترويجية ذات العلاقة.
            </p>
          </Col>
        </Row>
      )}

      <div>
        <center>
          {ln == "en" ? (
            <h2 className="h3b1 mt-5 mb-5">
              Additional Public Relations Services
            </h2>
          ) : (
            <h2 className="h3b1 mt-5 mb-5 ar1">
              خدمات إضافية في مجال العلاقات العامة
            </h2>
          )}
        </center>
      </div>
      {ln == "en" ? (
        <Row>
          <Col>
            <h2 className="h3b1">
              Corporate Social Responsibility (CSR) Activities
            </h2>
            <img src={Line} />
            <p className="en2">
              <br /> We can arrange CSR activities with government working
              bodies, private sector companies and non-profit organizations
              (NGOs), while ensuring that the activities are highly engaging, so
              they are highlighted in the media for more community awareness;
              quality & quantity wise.
            </p>
          </Col>

          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1">Events Management</h2>
            <img src={Line} />
            <p className="en2">
              We can assist in the entire process of event planning from
              developing the event’s concept and theme, bilingual needs, to
              contacting vendors for pricing and arrangements.
            </p>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1 ar1" dir="rtl">
              إدارة الفعاليات
            </h2>
            <img src={Line} style={{ float: "right" }} />
            <br />
            <p dir="rtl" className="ar2">
              نُوفر كل ما يتعلق بالتخطيط والتحضير لفعاليتكم التي تريدون تنظيمها،
              من إبتكار وتطوير مفهوم الفعالية المرجوة وشعارها وباللغات المطلوبة
              وطريقة التواصل مع مزودي الخدمات والحصول على أفضل الأسعار
              والترتيبات.
            </p>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1 ar1" dir="rtl">
              المسؤولية الإجتماعية المؤسساتية
            </h2>
            <img src={Line} style={{ float: "right" }} />
            <br />
            <p dir="rtl" className="ar2">
              نُقدم العديد من المبادرات والخدمات لدعم توجهات وجهود الجهات
              العاملة فيما يتعلق بالمسؤولية الإجتماعية، سواء كان ذلك للجهات
              الحكومية، شركات القطاع الخاص أو المؤسسات غير الربحية. نضمن قيامنا
              بذلك بتفاعلية ومشاركة عالية من قبل كل المعنيين وبتغطية مميزة في
              الإعلام لوعي مجتمعي اكثر كما وأكبر زخما .
            </p>
          </Col>
        </Row>
      )}

      {ln == "en" ? (
        <Row>
          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1">Media Relations</h2>
            <img src={Line} />
            <p className="en2">
              We can assist you with all your media and press-related needs.
              Whether it is a press release or maintaining positive relations
              with the press.
            </p>
          </Col>

          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1">Print & Digital Publishing</h2>
            <img src={Line} />
            <p className="en2">
              We provide the traditional print publishing methods and adapt to
              the changing tastes in publishing to an online audience.
            </p>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1 ar1" dir="rtl">
              الطباعة والنشر الرقمي
            </h2>
            <img src={Line} style={{ float: "right" }} />
            <br />
            <p dir="rtl" className="ar2">
              نُقدم خدمات الطباعة التقليدية ونعمل على مواكبة المتغيرات
              المُتسارعة الحاصلة في الأذواق المختلفة في عمليات النشر لإرضاء
              جمهور واسع عريض متنوع من مستخدمي الإنترنت .
            </p>
          </Col>

          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1 ar1" dir="rtl">
              العلاقات الإعلامية
            </h2>
            <img src={Line} style={{ float: "right" }} />
            <br />
            <p dir="rtl" className="ar2">
              قادرون على مساعدتك في تلبية كل إحتياجاتك الصحفية والإعلامية، سواء
              كان ذلك يتعلق باصدار البيانات الصحفية، إجراء المقابلات أو الحفاظ
              على علاقة إيجابية جيدة مع وسائل الإعلام المختلفة .
            </p>
          </Col>
        </Row>
      )}

      {ln == "en" ? (
        <Row>
          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1">Reputation Management</h2>
            <img src={Line} />
            <p className="en2">
              We will focus efforts on improving how the brand is perceived by
              key stakeholders which will ensure increased credibility and
              visibility across the target audience.
            </p>
          </Col>

          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1">Targeted Translation</h2>
            <img src={Line} />
            <p classN
            ame="en2">
              We will ensure that you receive the best possible translation to
              appeal to your diverse target audience in their preferred proper
              languages and venues.
            </p>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1 ar1" dir="rtl">
              الترجمة المُسْتَهدَفة
            </h2>
            <img src={Line} style={{ float: "right" }} />
            <br />
            <p dir="rtl" className="ar2">
              نضمن حصولك على أفضل خدمات الترجمة وإدارة المحتوى المصاحبة لذلك،
              لتلبية إحتياجات الجهات المُهتمة بذلك والمتطلعة لاستهداف عملائها
              وزبائنها والتواصل معهم باللغات والمحافل المناسبة .
            </p>
          </Col>

          <Col xs={12} md={6} lg={6}>
            <h2 className="h3b1 ar1" dir="rtl">
              إدارة السُّمْعَة
            </h2>
            <img src={Line} style={{ float: "right" }} />
            <br />
            <p dir="rtl" className="ar2">
              نُرَ كز جهودنا وخبراتنا في هذا المجال على طرق وآليات تحسين العلامة
              التجارية لجهة عاملة ما والكيفية التي ينظر إليها الشركاء
              والمساهمين، حيث سيمكننا ذلك من رفع معدلات الظهور والحضور
              والمصداقية لتلك الجهة على فئة كبيرة عريضة من الجمهور المُستهدف
              بشكل خاص والمجتمع ككل بشكل عام.
            </p>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Relations;
