import React from "react";
import "./styles.css";
import TethqarLogo from "../../assets/fugi_betamax.png";
import AboutusBG from "../../assets/About Us BG.png";
import AboutusHEAD from "../../assets/About Us Heading.png";
import AboutusSticker from "../../assets/About Us Stickers.png";
import { Col, Container, Row } from "react-bootstrap";

const AboutUs = (props) => {
  const { ln } = props;
  return (
    <div id="tethqeraboutus" className="aboutbackground">
      <div className="container">
        {ln == "en" ? (
          <Row>
            <Col xs={12} md={5} lg={6} className="p-5">
              <img src={TethqarLogo} className="w-100" />
            </Col>
            <Col xs={12} md={7} lg={6}>
              <div className="p-2 pt-5">
              <img src={AboutusHEAD} className="w-100"/>
              <p className="para4 w-85">We are a team of dedicated & nostalgic individuals 
                who are passionate about preserving all your 
                memories stored on old video tapes, audio 
                cassettes, CDs, DVDs & more. Our services allow 
                you to convert your precious memories to an 
                easily-accessible digital format so you can enjoy 
                them for years to come.</p>

                <p className="para4 w-85">TETHQAR is a service provided by of Advertiqa 
                Communications, Advertising & Marketing W.L.L.</p>
              </div>
              <div>
                <img src={AboutusSticker} className="w-100"/>
              </div>

            </Col>
          </Row>
        ) : (
          <Row className="rtl">
            <Col xs={12} md={5} lg={6} className="p-5">
              <img src={TethqarLogo} className="w-100" />
            </Col>
            <Col xs={12} md={7} lg={6}>
              <div className="p-2 pt-5 rightalign">
                <img src={AboutusHEAD} className="w-100"/>
                <p className="para4arb"
                >نحن فريق عمل متخصص بتجميع وتخزين ذكريات الماضي الخاصة بك على أشرطة فيديو وأشرطة صوتية "كاسيت"  والأقراص المدمجة/ المضغوطة وأقراص ڤيديو "دي في دي" والمزيد. كما تتيح خدماتنا تحويل الذكريات الثمينة إلى صيغة رقمية يسهل مشاهدتها والاستمتاع بها لسنوات قادمة.</p>

                <p className="para4arb"> تعد خدمة تذكار جزء من شركة ادفرتيكا للتواصل والإعلان والتسويق ذ.م.م</p>
              </div>
              <div>
                <img src={AboutusSticker} className="w-100"/>
              </div>

            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default AboutUs;
