import { Col, Row } from "react-bootstrap";

import ContentCreation from "../../assets/Social Media/creation.png";
import MediaBuying from "../../assets/Social Media/buying.png";
import AccountStrategy from "../../assets/Social Media/strategy.png";
import ContentPlanning from "../../assets/Social Media/planning.png";
import CampaignConcept from "../../assets/Social Media/campaign.png";
import AccManagement from "../../assets/Social Media/account management.png";

import TextCopy from "../../assets/Social Media/copy.png";
import GraphicDesign from "../../assets/Social Media/graphic.png";
import PhotoVideo from "../../assets/Social Media/photo.png";

import ContentCalendar from "../../assets/Social Media/calendar.png";
import CommunityEngagement from "../../assets/Social Media/community.png";
import Analytics from "../../assets/Social Media/analytics.png";

import SEO from "../../assets/Social Media/seo.png";
import ADS from "../../assets/Social Media/ads.png";

import "./styles.css";
import "../../Styles/fonts.css";

const Services = (props) => {
  const { ln } = props;
  return (
    <div>
      <div className="container">
        <center>
          {ln == "en" ? (
            <div className="border-box">
              <h1 className="hb1">Social Media Services</h1>
              <h5 style={{ color: "#000" }} className="text-center">
                A-Z customer-centric services
              </h5>
            </div>
          ) : (
            <div className="border-box">
              <h1 className="hb1 ar1">خدمات شبكات التواصل الإجتماعي</h1>
              <h5 style={{ color: "#000" }} className="text-center ar2">
                خدمات محورها العميل من الألف إلى الياء
              </h5>
            </div>
          )}
        </center>
        {ln == "en" ? (
          <Row>
            <Col xs={12} md={3} lg={3}>
              <center>
                <img src={AccountStrategy} width={90} />
              </center>

              <div>
                <h4 className="h3b1 text-center">Account strategy</h4>
                <ul className="ul">
                  <li> Conceptualizing </li>
                  <li>Content Planning</li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={3} lg={3}>
              <center>
                <img src={ContentCreation} width={90} />
              </center>

              <div>
                <h4 className="h3b1 text-center">Content Creation</h4>
                <ul className="ul">
                  <li>Text Copywriting</li>
                  <li>Photography</li>
                  <li>Graphic Design</li>
                  <li>Video Production</li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={3} lg={3}>
              <center>
                <img src={AccManagement} width={90} />
              </center>

              <div>
                <h4 className="h3b1 text-center">Account Management</h4>
                <ul className="ul">
                  <li> Content Calendars</li>
                  <li>Community Management</li>
                  <li>Analyics & Research </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={3} lg={3}>
              <center>
                <img src={MediaBuying} width={90} />
              </center>

              <div>
                <h4 className="h3b1 text-center">Media Buying</h4>
                <ul className="ul">
                  <li>Social Media Ads.</li>
                  <li>Search Engines.</li>
                  <li>Search Engine Optimization (SEO)</li>
                </ul>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={12} md={3} lg={3}>
              <center>
                <img src={MediaBuying} width={90} />
              </center>

              <div>
                <h4 className="h3b1 text-center ar1" dir="rtl">
                  شراء المواد الإعلامية
                </h4>
                <ul className="ul ar2" dir="rtl">
                  <li> الإعلان في وسائل التواصل الإجتماعي.</li>
                  <li> مُحركات البحث الرقمية.</li>
                  <li>
                    تحسين أداء محركات البحث الرقمية
                    <span className="ar1"> سِيُو </span>
                  </li>
                </ul>
              </div>
            </Col>

            <Col xs={12} md={3} lg={3}>
              <center>
                <img src={AccManagement} width={90} />
              </center>

              <div>
                <h4 className="h3b1 text-center ar1">إدارة حسابات العملاء</h4>
                <ul className="ul ar2" dir="rtl">
                  <li>رزنمة ووضع الجداول الزمنية لإصدار المحتوى </li>
                  <li>الإدارة المجتمعية </li>
                  <li>البحث التحليلي للبيانات </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={3} lg={3}>
              <center>
                <img src={ContentCreation} width={90} />
              </center>

              <div>
                <h4 className="h3b1 text-center ar1" dir="rtl">
                  صنع المحتو ى
                </h4>
                <ul className="ul ar2" dir="rtl">
                  <li> تأليف وكتابة وتدقيق المحتوى</li>
                  <li> التصوير الثابت</li>
                  <li> التصميم الغرافيكي</li>
                  <li>
                    إنتاج المواد المرئية المتحركة
                    <span className="ar1">(الفيديو)</span>
                  </li>
                </ul>
              </div>
            </Col>

            <Col xs={12} md={3} lg={3}>
              <center>
                <img src={AccountStrategy} width={90} />{" "}
              </center>

              <div>
                <h4 className="h3b1 ar1 text-center" dir="rtl">
                  الإستراتيجية الخاصة بالعملاء
                </h4>
                <ul className="ul ar2" dir="rtl">
                  <li> إستقراء ووضع المفاهيم </li>
                  <li>التخطيط لوضع المحتوى </li>
                </ul>
              </div>
            </Col>
          </Row>
        )}
      </div>

      <div className="container-fluid lb-box mt-5">
        {ln == "en" ? (
          <h1 className="hb1 text-center">Account Strategy</h1>
        ) : (
          <h1 className="hb1 text-center ar1" dir="rtl">
            الإستراتجية الخاصة بالعملاء
          </h1>
        )}
        {ln == "en" ? (
          <p className="text-center p-3 en2">
            Whether you are new to social media or want a refreshed presence, we
            will help you set your calendars, tone of voice and suggest digital
            marketing campaigns to fulfill your agenda using our renowned
            strategies & techniques.
          </p>
        ) : (
          <p className=" p-3 ar2" dir="rtl">
            نُساعد المُسجلين الملتحقين الجدد بشبكات التواصل الإجتماعي على خلق
            حضور متميز لهم، كذلك المتواجدين مسبقأ على تلك الشبكات بتحديث وتجديد
            حضورهم عليها. المهمة مهمتنا، حيث نعرف كيف نقوم بوضع وتنظيم رزنامتك
            وجداول أعمالك المختلفة، إظهار طلتك وإبراز نبرة صوتك، وإقتراح حملات
            التسويق الرقمية المناسبة لكل ذلك باستراتيجياتنا وتقنياتنا المتجددة.
          </p>
        )}
        <p className="text-center p-3 en2"></p>
        <div className="container">
          {ln == "en" ? (
            <Row>
              <Col xs={4} lg={1} md={1}>
                <img src={ContentPlanning} width={90} />
              </Col>

              <Col xs={8} lg={4} md={4}>
                <div>
                  <h4 className="h3b1">Content Planning</h4>
                  <p className="en2">
                    Whether it’s a simple post, or a complete social media
                    campaign, this part is essential for our team to come
                    together to decide on how and when to promote your product
                    or service.
                  </p>
                </div>
              </Col>
              <Col xs={12} lg={1} md={1}></Col>
              <Col xs={4} lg={1} md={1}>
                <img src={CampaignConcept} width={90} />
              </Col>
              <Col xs={8} lg={4} md={4}>
                <div>
                  <h4 className="h3b1">Campaign Conceptualizing</h4>
                  <p className="en2">
                    Here we answer your main fives: Why do you need a campaign?
                    What to say? How, when and where to say it? A tactical study
                    can let us know it all.
                  </p>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={8} lg={4} md={4}>
                <div>
                  <h4 className="h3b1 ar1" dir="rtl">
                    إستقراء ووضع مفاهيم
                    <br />
                    الحملات التسويقية
                  </h4>
                  <p dir="rtl" className="ar2">
                    في هذا الركن الهام من عملنا، سنقوم بالإجابة على الأسئلة
                    الخمسة الأكثر شيوعا لدى أصحاب القرار والمعنيين في هذا
                    المجال: لماذا نحتاج إلى إطلاق حملة تسويقية ترويجية؟ ماذا
                    علينا قوله؟ كيف؟ متى؟ وأين سنقوم بكل ذلك؟ نحن جاهزون للقيام
                    بإجراء الدراسات التكتيكية المستفيضة المعمقة المناسبة لمعرفة
                    كل ما يتعلق بالأمر.
                  </p>
                </div>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <img src={CampaignConcept} width={90} />
              </Col>
              <Col xs={12} lg={1} md={1}></Col>
              <Col xs={8} lg={4} md={4}>
                <div>
                  <h4 className="h3b1 ar1" dir="rtl">
                    إدارة المحتوى
                  </h4>
                  <p dir="rtl" className="ar2">
                    سواء كان منشورُك بسيطا أو سلسلة متواصلة من المنشورات
                    التزامُنية في إطار حملة كاملة شاملة على وسائل التواصل
                    الإجتماعي، فريق عملنا جاهزٌ للقيام بما يلزم للقاء والإجتماع
                    والنقاش سويا لنقرر الكيفية والتوقيت المناسبين للتسويق
                    والترويج لمنتجاتك أو خدماتك.
                  </p>
                </div>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <img src={ContentPlanning} width={90} />
              </Col>
            </Row>
          )}
          <center>
            <div className="bluebox">
              {ln == "en" ? (
                <h7 className="text-center">
                  “Without strategy, content is just stuff, and the world has
                  enough stuff,” <br />
                  -Arjun Basu (Writer & Novelist)
                </h7>
              ) : (
                <p dir="rtl" className="ar2 text-center">
                  "بدون إستراتيجية، يُعتبر المحتوى مجرد كلام، فلدى العالم ما هو
                  كافٍ من ذلك"
                  <br />– أرجون باسو( كاتب وروائي قصصي )
                </p>
              )}
            </div>
          </center>
        </div>
      </div>

      <div className="container-fluid lp-box">
        {ln == "en" ? (
          <h1 className="hp1">Content Creation</h1>
        ) : (
          <h1 className="hp1 ar1">صناعة المحتو ى</h1>
        )}

        {ln == "en" ? (
          <p className="text-center p-1">
            Creating valuable, relevant and consistent content are significant
            factors in attracting and retaining a well-defined audience and
            prompting the desired action. In response, we handle the matter that
            ranges from text, images, videos to animation, the target
            platform-specific content helps drive profitable customer action.
          </p>
        ) : (
          <p className=" p-1 ar2" dir="rtl">
            تُشَكل عملية صنع محتوى ذو قيمة عالية عاملاً هاما في الجاذبية
            والإحتفاظ بقطاع معين محدد من العُملاء. وللتجاوب مع ذلك، نقوم
            بالتعاطي المناسب مع الأمر، بداية من النص التحريري، الصُّوَر
            الفوتوغرافية الثابتة، المواد المرئية المتحركة (الفيديو) إلى المواد
            الح يوية المُنتَجة (أنيميشن). طبيعة المحتوى لمنصة رقمية ما مُستهدفة
            تتسبب في خلق ردة فعل إيجابية بعوائد مُربحة على العملاء.
          </p>
        )}
        <div className="container">
          {ln == "en" ? (
            <Row>
              <Col xs={4} lg={1} md={1}>
                <img src={TextCopy} width={90} />
              </Col>
              <Col xs={8} lg={4} md={4}>
                <div>
                  <h4 className="h3p1">Text Copywriting</h4>
                  <p className="en2">
                    Our creative copywriters script stories that stand out and
                    appeal to the audience, with the right characters, conflicts
                    and resolution. They call the audience to action, fulfilling
                    the purpose behind the story and so the marketing idea.
                  </p>
                </div>
              </Col>
              <Col xs={12} lg={1} md={1}></Col>
              <Col xs={4} lg={1} md={1}>
                <img src={GraphicDesign} width={90} />
              </Col>
              <Col xs={8} lg={4} md={4}>
                <div>
                  <h4 className="h3p1">Graphic Design</h4>
                  <p className="en2">
                    Images attract customers’ attention and help promote your
                    business. Our graphic designers created the best visual
                    Appeal for your products and services.
                  </p>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={8} lg={4} md={4}>
                <div>
                  <h4 className="h3p1 ar1" dir="rtl">
                    التصميم الغرافيكي
                  </h4>
                  <p dir="rtl" className="ar2">
                    لا يختلف إثنان في القدرة الخارقة للصورة على جذب الإهتمام
                    ولفت النظر لأي شيء كان. يضم فريق عملنا نخبة من مصممي
                    الغرافيك القادرين على تقديم الأفضل والأكثر تميزاً وجذبا
                    لمنتجات وخدمات عملائنا الكرام.
                  </p>
                </div>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <img src={GraphicDesign} width={90} />
              </Col>
              <Col xs={1} lg={1} md={1}></Col>
              <Col xs={7} lg={4} md={4}>
                <div>
                  <h4 className="h3p1 ar1" dir="rtl">
                    كتابة النصوص
                  </h4>
                  <p dir="rtl" className="ar2">
                    يضم فريق عملنا عدداً من كُتاب السيناريو المبدعين في كتابة
                    نصوص الأفكار والتجارب القصصية التي تتميز وتروق عن غيرها
                    لقطاعات مختلفة من جمهور العملاء، مع الشخصيات والأزمات
                    والإجراءات الحاسمة، وعليه يجدون الطريقة المناسبة للفت نظر
                    الجمهور للتصرف بردة الفعل المُحفزة والمناسبة المتماشية مع
                    الهدف المرجو من التجربة القصصية أو الفكرة التسويقية .
                  </p>
                </div>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <img src={TextCopy} width={90} />
              </Col>
            </Row>
          )}
          {ln == "en" ? (
            <Row>
              <Col xs={12} lg={4} md={4}></Col>
              <Col xs={4} lg={1} md={1}>
                <img src={PhotoVideo} width={90} />
              </Col>
              <Col xs={8} lg={4} md={4}>
                <div>
                  <h4 className="h3p1">Photo & Video Production</h4>
                  <p className="en2">
                    Videos and animations have the highest visual appeal in a
                    content. We create high-quality videos and animations to
                    help promote your products and services.
                  </p>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={12} lg={4} md={4}></Col>

              <Col xs={8} lg={4} md={4}>
                <div>
                  <h4 className="h3p1 ar1" dir="rtl">
                    التصوير وصناعة المواد المرئية (الفيديو)
                  </h4>
                  <p dir="rtl" className="ar2">
                    تلعب المواد المرئية الحركية (الفيديو) ومواد الرسوم المتحركة
                    الحيوية (أنيميشن) دوراً هاما جداً في مستوى جاذبية المحتوى.
                    نصنع ونقوم بإنتاج الفيديوهات ومواد الرسوم المتحركة الحيوية
                    (أنيميشن) الأكثر إثارة وجودة للجذب في الترويج والتسويق
                    لمنتجات وخدمات عملائنا.
                  </p>
                </div>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <img src={PhotoVideo} width={90} />
              </Col>
            </Row>
          )}
          {ln == "en" ? (
            <center>
              <div className="purplebox">
                <h7 className="text-center p-3  en2">
                  "Only 1% of the world creates content, while the other 99%
                  simply consumes it."
                  <br /> - 1% Rule of Interest Culture
                </h7>
              </div>
            </center>
          ) : (
            <center>
              <p className="text-center p-3 purplebox ar2">
                <span className="en2" dir="ltr">
                  1%"
                </span>
                " من العالم يصنع المحتوى ، بينما يستهلكه ال %
                <span className="en2">99</span> الآخرون الباقون
                <span className="en2">"</span>
                <br />– قانون الواحد بالمئة من ثقافة الإنترنت
              </p>
            </center>
          )}
        </div>
      </div>

      <div className="container-fluid lb-box">
        {ln == "en" ? (
          <div>
            <h1 className="hb1 text-center">Account Management</h1>
            <p className="text-center p-3 en2">
              Subsequent to thoughtful content creation, it’s important to
              maintain consistent activities on various social media platforms,
              monitor audiences’ engagement, analyze the results and make
              content strategies to follow. Through this, the brand would create
              a social persona and increase customer loyalty.
            </p>
          </div>
        ) : (
          <div>
            <h1 className="hb1 text-center ar1" dir="rtl">
              إدارة حسابات العملاء
            </h1>
            <p className=" p-3 ar2" dir="rtl">
              بعد صناعة وانتاج محتوى مدروس بعناية فائقة، يُشَكل الحفاظ على
              نشاطات وتفاعلات تسلسلية توافقية تزامنية متناسقة على وسائل التواصل
              الإجتماعي أهمية بالغة جداً في مراقبة تفاعل ومشاركة الناشطين ورُواد
              تلك الشبكات. تنبع تلك الأهمية من قدرتها على تحليل البيانات
              واتجاهاتها المرورية لوضع استراتيجيات المحتوى المتعلقة بها. من هنا
              تبدأ عملية خلق العلامة التجارية أو الهوية المؤسسية لجهة عاملة ما
              وترسيخها ومعرفة درجات ولاء العُملاء لها .
            </p>
          </div>
        )}
        <div className="container">
          {ln == "en" ? (
            <Row>
              <Col xs={4} lg={1} md={1}>
                <img src={ContentCalendar} width={90} />
              </Col>
              <Col xs={8} lg={4} md={4}>
                <div>
                  <h4 className="h3b1">Content Calendars</h4>
                  <p className="en2">
                    Through this commonly used technique, we will provide you
                    with a strategic plan of when to promote and market your
                    brand in order to ensure the highest engagement from the
                    audience.
                  </p>
                </div>
              </Col>
              <Col xs={12} lg={1} md={1}></Col>
              <Col xs={4} lg={1} md={1}>
                <img src={CommunityEngagement} width={90} />
              </Col>
              <Col xs={8} lg={4} md={4}>
                <div>
                  <h4 className="h3b1">Community Engagement</h4>
                  <p className="en2">
                    Customer experience monitoring tools help us assess
                    customers’ moods and market trends, and initiate timely
                    activities for your products & services; ensuring optimal
                    customer engagement.
                  </p>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={8} lg={4} md={4}>
                <div>
                  <h4 className="h3b1 ar1" dir="rtl">
                    الجداول الزمنية (الرزنامات) الخاصة بالمحتوى
                  </h4>
                  <p dir="rtl" className="ar2">
                    نقوم بتقديم وتوفير خطة استراتيجية زمنية لتحديد الأوقات
                    المناسبة لنشر محتوى الترويج و التسويق لعلامتك التجارية، وذلك
                    لضمان الحصول على أعلى معدلات التفاعل والمشاركة من الجمهور
                    المُستهدف .
                  </p>
                </div>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <img src={ContentCalendar} width={90} />
              </Col>
              <Col xs={12} lg={1} md={1}></Col>

              <Col xs={8} lg={4} md={4}>
                <div>
                  <h4 className="h3b1 ar1" dir="rtl">
                    المشاركة المُجتمعية
                  </h4>
                  <p dir="rtl" className="ar2">
                    تُوَفر أدوات مراقبة ومتابعة إقتراحات وتجارب واهتمامات
                    العملاء لنا القدرة على تقييم مزاجيات وأذواق قطاعات مختلفة من
                    المجتمع، لمواكبة آخر المتغيرات التي تطرأ على الأسواق بين
                    الحين والآخر. نبدأ بالمبادرة بإجراءات ونشاطات معينة في أوقات
                    محددة لتساهم بذلك في الترويج والتسويق لمنتجاتك وخدماتك لضمان
                    أعلى مستويات التفاعل والمشاركة من الجمهور.
                  </p>
                </div>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <img src={CommunityEngagement} width={90} />
              </Col>
            </Row>
          )}
          {ln == "en" ? (
            <Row>
              <Col xs={12} lg={4} md={4}></Col>
              <Col xs={4} lg={1} md={1}>
                <img src={Analytics} width={90} />
              </Col>
              <Col xs={8} lg={4} md={4}>
                {ln == "en" ? (
                  <div>
                    <h4 className="h3b1">Analytics Research</h4>
                    <p className="en2">
                      With the help of conventional analytic tools, we suggest
                      strategic plans to increase audience engagement,
                      accompanied by analytical insights.
                    </p>
                  </div>
                ) : (
                  <div>
                    <h4 className="h3b1 ar1">البحث التحليلي للبيانا ت</h4>
                    <p dir="rtl" className="ar2">
                      نُقدم خُططا وحلولاً استراتيجية لعملائنا الكرام من خلال
                      الاستعانة بأدوات ووسائل التحليل التقليدية للبيانات
                      المتوفرة، لرفع مستويات مشاركة وتفاعل كل قطاعات المجتمع،
                      وبالتوازي مع متابعة التقييمات التحليلية.
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={12} lg={4} md={4}></Col>

              <Col xs={8} lg={4} md={4}>
                <div>
                  <h4 className="h3b1 ar1" dir="rtl">
                    البحث التحليلي للبيانا ت
                  </h4>
                  <p dir="rtl" className="ar2">
                    نُقدم خُططا وحلولاً استراتيجية لعملائنا الكرام من خلال
                    الاستعانة بأدوات ووسائل التحليل التقليدية للبيانات المتوفرة،
                    لرفع مستويات مشاركة وتفاعل كل قطاعات المجتمع، وبالتوازي مع
                    متابعة التقييمات التحليلية.
                  </p>
                </div>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <img src={Analytics} width={90} />
              </Col>
            </Row>
          )}

          <center>
            <div className="bluebox">
              {ln == "en" ? (
                <h7 className="text-center p-3  en2">
                  “We don't have a choice on whether we do social media, the
                  question is how well we do it”.
                  <br /> - Erik Qualman (Motivational Speaker)
                </h7>
              ) : (
                <center>
                  <p dir="rtl" className="ar2 text-center">
                    "لا يوجد خيار لدينا عندما يتعلق الأمر بعمل شبكات تواصل
                    إجتماعي. السؤال هو كيف لنا ان نفعل ذلك بشكل جيد "
                    <br />- إريك كوالمان (مُحاضر مُلْهِم )
                  </p>
                </center>
              )}
            </div>
          </center>
        </div>
      </div>

      <div className="container-fluid lp-box">
        {ln == "en" ? (
          <div>
            <h1 className="hp1">Media Buying</h1>
            <p className="text-center p-3 en2">
              From Search Engine Optimization (SEO), influencers, advertisements
              on social media platforms to online, we assist you with your
              strategized campaigns.
            </p>
          </div>
        ) : (
          <div>
            <h1 className="hp1 ar1">شراء المواد الإعلامية</h1>
            <p className=" p-3 ar2" dir="rtl">
              من الاستخدام الأمثل لمحركات البحث الرقمية، الى المؤثرين، الإعلانات
              على منصات وسائل التواصل الإجتماعي إلى حملات الترويج والتسويق
              الرقمية، نُلبي كل إحتياجاتكم لمساعدتكم وتقديم الأفضل لكم في
              حَمَلاتكم الإستراتيجية .
            </p>
          </div>
        )}

        <div className="container">
          {ln == "en" ? (
            <Row>
              <Col xs={4} lg={1} md={1}>
                <img src={ADS} width={90} />
              </Col>
              <Col xs={8} lg={4} md={4}>
                {ln == "en" ? (
                  <div>
                    <h4 className="h3p1">Social Media Ads</h4>
                    <p className="en2">
                      With the annual rise in social media use, it is a perfect
                      place to showcase what your business has to offer to
                      potential customers.
                    </p>
                  </div>
                ) : (
                  <div>
                    <h4 className="h3p1 ar1">
                      الإعلانات على وسائل التواصل الاجتماعي
                    </h4>
                    <p dir="rtl" className="ar2">
                      مع الإرتفاع السنوي في استعمال شبكات التواصل الإجتماعي،
                      فلقد أصبحت المكان المثالي لعرض ما في جعبة جهتك العاملة من
                      منتجات وخدمات وما تُمثِ لُه علامتك التجارية وما يمكنك
                      تقديمه لعملائك <span className="arb2">المهمين</span>
                      المُحتملين.
                    </p>
                  </div>
                )}
              </Col>
              <Col xs={12} lg={1} md={1}></Col>
              <Col xs={4} lg={1} md={1}>
                <img src={SEO} width={90} />
              </Col>
              <Col xs={8} lg={4} md={4}>
                {ln == "en" ? (
                  <div>
                    <h4 className="h3p1">Search Engine Optimization</h4>
                    <p className="en2">
                      Through Search Engine Optimization, we guarantee
                      maximizing the number of visitors to your website and
                      platform, making them appear in highly visible areas in
                      search engineer’s results
                    </p>
                  </div>
                ) : (
                  <div>
                    <h4 className="h3p1 ar1">
                      تحسين أداء محركات البحث الرقمية
                    </h4>
                    <p dir="rtl" className="ar2">
                      نضمن لك أعلى معدلات جذب الزوار لموقعك الألكتروني ومنصتك
                      الرقمية، من خلال تحسين أداء محركات البحث الرقمية
                      والإستخدام الأمثل لها، لتكون علامتك التجارية وجهتك العاملة
                      من بين أعلى المواقع وأكثر المنصات ظهوراً وبروزاً وحضوراً
                      على تلك المُحركات باختلافاتها وتعددها.
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={8} lg={4} md={4}>
                <div>
                  <h4 className="h3p1 ar1" dir="rtl">
                    تحسين أداء محركات البحث الرقمية
                  </h4>
                  <p dir="rtl" className="ar2">
                    نضمن لك أعلى معدلات جذب الزوار لموقعك الألكتروني ومنصتك
                    الرقمية، من خلال تحسين أداء محركات البحث الرقمية والإستخدام
                    الأمثل لها، لتكون علامتك التجارية وجهتك العاملة من بين أعلى
                    المواقع وأكثر المنصات ظهوراً وبروزاً وحضوراً على تلك
                    المُحركات باختلافاتها وتعددها.
                  </p>
                </div>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <img src={SEO} width={90} />
              </Col>
              <Col xs={12} lg={1} md={1}></Col>
              <Col xs={8} lg={4} md={4}>
                <div>
                  <h4 className="h3p1 ar1" dir="rtl">
                    الإعلانات على وسائل التواصل الاجتماعي
                  </h4>
                  <p dir="rtl" className="ar2">
                    مع الإرتفاع السنوي في استعمال شبكات التواصل الإجتماعي، فلقد
                    أصبحت المكان المثالي لعرض ما في جعبة جهتك العاملة من منتجات
                    وخدمات وما تُمثِ لُه علامتك التجارية وما يمكنك تقديمه
                    لعملائك المهمين المُحتملين.
                  </p>
                </div>
              </Col>
              <Col xs={4} lg={1} md={1}>
                <img src={ADS} width={90} />
              </Col>
            </Row>
          )}
          <center>
            <div className="purplebox">
              {ln == "en" ? (
                <h7 className="text-center p-3  en2">
                  “Social media marketing is about identifying the essence of
                  engagement.”
                  <br /> - Edgerank Checker
                </h7>
              ) : (
                <center>
                  <p dir="rtl" className="ar2 text-center">
                    "التسويق في وسائل التواصل الإجتماعي هو تحديد جوهر
                    المُشارَكة"
                    <br />
                    ’إيدْجْرَانك تْشِكَر‘: شركة رائدة متخصصة في رصد ومتابعة أداء
                    وخوارزميات المحتوى الرقمي
                  </p>
                </center>
              )}
            </div>
          </center>
        </div>
      </div>
    </div>
  );
};

export default Services;
