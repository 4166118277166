import { makeStyles } from "@material-ui/core/styles";
import LINES2 from "../../assets/LINES2.png";

const drawerWidth = 0;

export default makeStyles((theme) => ({
  section1:{
    padding:0
  },
  section2: {
    padding: 50,
  },
  activeBn: {
    borderWidth: 0,
    borderLeftWidth: 5,
    borderLeftColor: "#96153A",
    color: "#96153A",
    background: "none",
    fontWeight: "bold",
    fontSize: 20,
  },
  sideImage:{
    width:"100%",
    height:"100%",
    objectFit:'cover'
  },
  activeBn2: {
    borderWidth: 0,
    borderRightWidth: 5,
    borderRightColor: "#96153A",
    color: "#96153A",
    background: "none",
    fontWeight: "bold",
    fontSize: 20,
  },
  disabledBn: {
    background: "none",
    border: "none",
    color: "#999",
    fontSize: 20,
  },
  headTxt: {
    color: "#96153a",
  },
}));
