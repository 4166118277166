import { Container, Row, Col } from "react-bootstrap";
import TouchingPic from "../../assets/touching2.jpg";
const Profile = () => {
  return (
    <div className="container-fluid">
      <img src={TouchingPic} className="w-100" />
    </div>
  );
};

export default Profile;
