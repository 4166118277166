import { Row, Col } from "react-bootstrap";

const Event = (props) => {
  const { ln } = props;
  return (
    <div className="container">
      <Row>
        <Col xs={12} md={6} lg={6}>
          <center>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/JzFwqGfYE_E"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </center>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/9l85jZaeHEc"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Col>
      </Row>

      {/* <Col xs={12} lg={6}>
          {ln == "en" ? (
            <div
              className="p-5"
              style={{ backgroundColor: "#c3e3f7", height: 315 }}
            >
              <h4>KAZADOO EVENT</h4>
              <p>
                Through our ePubliqa service,we provide the traditional print
                publishing methods and transcend to adapt the changinng tastes
                in publishing to an online audience.
              </p>
            </div>
          ) : (
            <div
              className="p-5"
              style={{ backgroundColor: "#c3e3f7", height: 315 }}
            >
              <h4 dir="rtl">فعالية مسرح "كازادوو"</h4>
              <p dir="rtl">
                من خلال خدمة النشر الرقمي لدينا، نقوم بتقديم خدمات وأساليب
                الطباعة التقليدية في النشر، ثم نقوم بتحويلها لتوائم وتتأقلم مع
                الأذواق المُتَغَيِّرة في التعاطي مع طريقة النشر وذلك لجمهور
                مستخدمي الإنترنت.
              </p>
            </div>
          )}
        </Col> */}
    </div>
  );
};

export default Event;
