import { React, useEffect } from "react";
import { Carousel, Button, Container, Row, Col } from "react-bootstrap";
import useStyles from "./styles";
import MissionPic1 from "../../assets/Mision&Vision/mission-en.jpg";
import VisionPic1 from "../../assets/Mision&Vision/vision-en.jpg";

import MissionPic2 from "../../assets/Mision&Vision/mission-ar.jpg";
import VisionPic2 from "../../assets/Mision&Vision/vision-ar.jpg";

import MissionIcon from "../../assets/Mision&Vision/mission.png";
import VisionIcon from "../../assets/Mision&Vision/vision.png";
import Aos from "aos";

const Mission = (props) => {
  const { ln } = props;
  const classes = useStyles();

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="container-fluid">
      {ln == "en" ? (
        <Row>
          <Col className={classes.mission} xs={12} md={6} lg={6}>
            <Row>
              <Col xs={1} md={3} lg={3}></Col>
              <Col xs={10} md={6} lg={6}>
                <center>
                  <img
                    src={MissionIcon}
                    style={{ width: 180, marginTop: 10, marginBottom: 10 }}
                  />

                  <h2 className={classes.head}>OUR MISSION</h2>
                </center>
              </Col>
              <Col xs={1} md={3} lg={3}></Col>
            </Row>
            <Row className="pb-5">
              <Col></Col>
              <Col xs={12} lg={8} md={8}>
                <p className="en2 wp">
                  Commit to our creative capabilities and business ideals,
                  promote the reach and reputation of our customers’ brand and
                  help them realize their business aspirations.
                </p>
              </Col>
              <Col></Col>
            </Row>
          </Col>
          <Col className={classes.vision} xs={12} md={6} lg={6}>
            <Row>
              <Col xs={1} md={3} lg={3}></Col>
              <Col xs={10} md={6} lg={6}>
                <center>
                  <img
                    src={VisionIcon}
                    style={{ width: 180, marginTop: 10, marginBottom: 10 }}
                  />
                  <h2 className="hw1">OUR VISION</h2>
                </center>
              </Col>
              <Col xs={1} md={3} lg={3}></Col>
            </Row>
            <Row className="pb-5">
              <Col></Col>
              <Col xs={12} lg={8} md={8}>
                <p className="en2 wp">
                  To establish ourselves as an innovative and ideal company in
                  Communication, Marketing and Advertising in the State of
                  Qatar.
                </p>
              </Col>
              <Col></Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col className={classes.vision} xs={12} md={6} lg={6}>
            <Row>
              <Col xs={1} md={3} lg={3}></Col>
              <Col xs={10} md={6} lg={6}>
                <center>
                  <img
                    src={VisionIcon}
                    style={{ width: 180, marginTop: 10, marginBottom: 10 }}
                  />
                  <h1 className="hw1 ar1" dir="rtl">
                    رؤيتنا
                  </h1>
                </center>
              </Col>
              <Col xs={1} md={3} lg={3}></Col>
            </Row>
            <Row className="pb-5">
              <Col></Col>
              <Col xs={12} lg={8} md={8}>
                <p className="pw1 ar2" dir="rtl">
                  ترسيخ أنفسنا كشركة رائدة مُبتكرة ومثالية في مجالات الإتصال
                  والتواصل الإعلامي والإعلانات والتسويق في دولة قطر.
                </p>
              </Col>
              <Col></Col>
            </Row>
          </Col>

          <Col className={classes.mission} xs={12} md={6} lg={6}>
            <Row>
              <Col xs={1} md={3} lg={3}></Col>
              <Col xs={10} md={6} lg={6}>
                <center>
                  <img
                    src={MissionIcon}
                    style={{ width: 180, marginTop: 10, marginBottom: 10 }}
                  />
                  <h2 className="hw1 ar1" dir="rtl">
                    رسالتنا
                  </h2>
                </center>
              </Col>
              <Col xs={1} md={3} lg={3}></Col>
            </Row>
            <Row className="pb-5">
              <Col></Col>
              <Col xs={12} lg={8} md={8}>
                <p className="pw1 ar2" dir="rtl">
                  الإلتزام بقدراتنا في الإبتكار وبمُثُلِنا العليا في إدارة
                  الأعمال. تعزيز وصول واستدامة مكانة العلامة التجارية لعملائنا
                  ومساعدتهم في تحقيق أهدافهم في مجالات أعمالهم المختلفة .
                </p>
              </Col>
              <Col></Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Mission;
