import { makeStyles } from "@material-ui/core/styles";
import LINES from "../../assets/LINES.png";

const drawerWidth = 0;

export default makeStyles((theme) => ({
  About: {
    backgroundImage: `url(${LINES})`,

    backgroundRepeat: "no-repeat",
  },
  about2: {
    padding: 10,
    margin: 50,
  },
  head1: {
    fontSize: 35,

    color: "#96153A",
    textAlign: "left",
  },
  head2: {
    color: "#000",
    textAlign: "left",
    marginTop: 30,
    marginBottom: 30,
  },
  head1r: {
    fontSize: 35,

    color: "#96153A",
    textAlign: "right",
  },
  head2r: {
    color: "#000",
    textAlign: "right",
    marginTop: 30,
    marginBottom: 30,
  },
  card: { boxShadow: `1px 1px 10px #aaa`, height: 100, margin: 50 },
  para: {
    textAlign: "justify",
  },
  para2: {
    textAlign: "justify",
  },
}));
