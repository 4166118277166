import { React, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Line from "../../assets/line.png";
import Accountability from "../../assets/VALUES/acc.png";
import Trust from "../../assets/VALUES/trust.png";
import Respect from "../../assets/VALUES/resp.png";
import Passion from "../../assets/VALUES/pass.png";
import Quality from "../../assets/VALUES/qual.png";
import "./styles.css";
import Aos from "aos";

const Values = (props) => {
  const { ln } = props;
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="container mt-5 values">
      {ln == "en" ? (
        <div>
          <center>
            <h1 className="hb1">OUR VALUES</h1>
          </center>
          <center>
            <img src={Line} className="mb-3" />
          </center>
          <Row>
            <Col data-aos="fade-right" data-aos-once="true">
              <center>
                <img src={Accountability} />
              </center>
              <br />

              <h5 className="txt1 en2">Accountability</h5>
            </Col>
            <Col data-aos="fade-right" data-aos-once="true">
              <center>
                <img src={Passion} />
              </center>
              <br />

              <h5 className="txt1 en2">Passion</h5>
            </Col>
            <Col data-aos="fade-up" data-aos-once="true">
              <center>
                <img src={Quality} />
              </center>
              <br />

              <h5 className="txt1 en2">Quality</h5>
            </Col>
            <Col data-aos="fade-left" data-aos-once="true">
              <center>
                <img src={Trust} />
              </center>
              <br />

              <h5 className="txt1 en2">Trust</h5>
            </Col>
            <Col data-aos="fade-left" data-aos-once="true">
              <center>
                <img src={Respect} />
              </center>
              <br />

              <h5 className="txt1 en2">Respect</h5>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <center>
            <h1 className="hb1 ar1">قِيَمُنا</h1>
          </center>
          <center>
            <img src={Line} className="mb-3" />
          </center>
          <Row>
            <Col data-aos="fade-right" data-aos-once="true">
              <center>
                <img src={Respect} />
              </center>
              <br />

              <h5 className="txt1 ar2">الإحترام</h5>
            </Col>
            <Col data-aos="fade-right" data-aos-once="true">
              <center>
                <img src={Trust} />
              </center>
              <br />

              <h5 className="txt1 ar2">الثقة والأمانة </h5>
            </Col>
            <Col data-aos="fade-up" data-aos-once="true">
              <center>
                <img src={Quality} />
              </center>
              <br />

              <h5 className="txt1 ar2">الجودة</h5>
            </Col>
            <Col data-aos="fade-left" data-aos-once="true">
              <center>
                <img src={Passion} />
              </center>
              <br />

              <h5 className="txt1 ar2">الشغف</h5>
            </Col>
            <Col data-aos="fade-left" data-aos-once="true">
              <center>
                <img src={Accountability} />
              </center>
              <br />

              <h5 className="txt1 ar2">المسؤولية</h5>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};
export default Values;
