import { React, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./styles.css";
import Logo from "../../assets/icon.png";
import Aos from "aos";
import emailjs from "@emailjs/browser";

const Contact = (props) => {
  const { ln } = props;
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const name = data.get("name");
    const email = data.get("email");
    const message = data.get("message");
    const phone = data.get("phone");
    console.log(name);

    if (name != "" && phone != "" && email != "" && message != "") {
      if (isNumeric(phone) == true) {
        emailjs
          .sendForm(
            "service_p3dyzfp",
            "template_xrj2bu9",
            form.current,
            "user_oR9DBc6thamxz5V799jBX"
          )
          .then(
            (result) => {
              alert("Message sent !");
              document.getElementById("form").reset();
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
      } else {
        alert("Please enter a valid number !");
      }
    } else {
      alert("Please fill out all details !");
    }
  };

  return (
    <div id="contact">
      <div className="container-fluid mt-5 mb-5">
        {ln == "en" ? (
          <Row>
            <Col
              className="section1 p-0"
              xs={12}
              md={6}
              lg={6}
              data-aos="fade"
              data-aos-once="true"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.02171242598!2d51.51428971447664!3d25.26985503495126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c56074b13687%3A0x951aaf5be4ce076!2sAdvertiqa%20Communications%2C%20Advertising%20%26%20Marketing%20W.L.L.!5e0!3m2!1sen!2sin!4v1638847548856!5m2!1sen!2sin"
                className="w-100 h-100"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
              <div className="blueOverlay"></div>
            </Col>
            <Col className="section2 p-5" xs={12} md={6} lg={6}>
              <h5 className="hw1">
                You have questions ? Contact us today, we're here to help
              </h5>
              <Form ref={form} onSubmit={sendEmail} id="form">
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Control type="text" placeholder="Name" name="name" />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control
                      type="number"
                      placeholder="Phone Number"
                      type="tel"
                      name="phone"
                      maxLength={11}
                    />
                  </Form.Group>
                </Row>

                <Form.Group className="mb-3" controlId="formGridEmail">
                  <Form.Control placeholder="Email" type="email" name="email" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGridAddress2">
                  <Form.Control placeholder="Your Message" name="message" />
                </Form.Group>

                <Button
                  variant="danger"
                  type="submit"
                  className="w-100"
                  style={{ backgroundColor: "#96153A" }}
                >
                  SEND MESSAGE
                </Button>
              </Form>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col className="section2 p-5" xs={12} md={6} lg={6} dir="rtl">
              <h5 className="hw1 ar1">
                هل لديك اسئلة ؟ تواصل معنا الآن، نحن هنا للمساعدة
              </h5>
              <Form ref={form} onSubmit={sendEmail} id="form">
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Control type="text" placeholder="الاسم" name="name" />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control
                      type="phone"
                      placeholder="رقم الهاتف"
                      name="phone"
                      maxLength={11}
                    />
                  </Form.Group>
                </Row>

                <Form.Group className="mb-3" controlId="formGridEmail">
                  <Form.Control
                    type="email"
                    placeholder="البريد الإلكتروني"
                    name="email"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGridAddress2">
                  <Form.Control placeholder="رسالتك" name="message" />
                </Form.Group>

                <Button
                  variant="danger"
                  type="submit"
                  className="w-100"
                  style={{ backgroundColor: "#96153A" }}
                >
                  ارسل رسالة
                </Button>
              </Form>
            </Col>
            <Col className="section1 p-0" xs={12} md={6} lg={6}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.02171242598!2d51.51428971447664!3d25.26985503495126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c56074b13687%3A0x951aaf5be4ce076!2sAdvertiqa%20Communications%2C%20Advertising%20%26%20Marketing%20W.L.L.!5e0!3m2!1sen!2sin!4v1638847548856!5m2!1sen!2sin"
                className="w-100 h-100"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
              <div className="blueOverlay"></div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default Contact;
