import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import useStyles from "./pstyles";
import ContactIcon from "../../assets/contacts.png";
import TickIcon from "../../assets/tick.png";
import Line from "../../assets/line2.png";
import Aos from "aos";

const PromosFeatures = (props) => {
  const { ln } = props;
  const classes = useStyles();

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="container">
      {ln == "en" ? (
        <h1 className={classes.head1}>FEATURES</h1>
      ) : (
        <h1 className="hp1 ar1">المزايا</h1>
      )}
      <center>
        <img src={Line} />
      </center>
      {ln == "en" ? (
        <Row className="mt-5">
          <Col></Col>
          <Col xs={12} lg={3} md={3} className="p-3">
            <div
              className={classes.imgCont}
              data-aos="fade-right"
              data-aos-once="true"
            >
              <img src={TickIcon} style={{ width: 200, height: 210 }} />
            </div>

            <h3 className="h3p1 mt-3">Notifications</h3>

            <p>
              Stay up to date with the latest deals & arrivals of thousands of
              stores in Qatar.
            </p>
          </Col>
          <Col xs={12} lg={1} md={1}></Col>
          <Col xs={12} lg={3} md={3} className="p-3">
            <div
              className={classes.imgCont}
              data-aos="fade-left"
              data-aos-once="true"
            >
              <img src={ContactIcon} style={{ width: 200, height: 210 }} />
            </div>
            <h3 className="h3p1 mt-3">Business Directory</h3>

            <p>
              Find more information about your desired stores; including contact
              numbers, address/location, web-site, and all deals they have.
            </p>
          </Col>
          <Col></Col>
        </Row>
      ) : (
        <Row className="mt-5">
          <Col></Col>
          <Col xs={12} lg={3} md={3} className="p-3">
            <div className={classes.imgCont}>
              <img src={ContactIcon} style={{ width: 200, height: 210 }} />
            </div>
            <h3 className="h3p1 ar1 mt-3" dir="rtl">
              دليل أعمال
            </h3>

            <p dir="rtl" className="ar2">
              قم بالإطلاع على المزيد من المعلومات عن متاجرك ومحلاتِك المفضلة
              كأرقام هواتف التواصل والعنوان / المكان والموقع الألكتروني وكل ما
              لديها من عروض خاصة جذابة.
            </p>
          </Col>
          <Col xs={12} lg={1} md={1}></Col>

          <Col xs={12} lg={3} md={3} className="p-3">
            <div className={classes.imgCont}>
              <img src={TickIcon} style={{ width: 200, height: 210 }} />
            </div>
            <h3 className="h3p1 ar1 mt-3" dir="rtl">
              الإشعارات
            </h3>

            <p dir="rtl" className="ar2 ">
              واكب وتابع آخر أخبار العروض - وآخر المعروضات التي وصلت حديثا لآلاف
              المتاجر في دولة قطر.
            </p>
          </Col>
          <Col></Col>
        </Row>
      )}
    </div>
  );
};

export default PromosFeatures;
