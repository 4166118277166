import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyCy_7IHgTHA0cf8cLiIB1uPJawUy4fE0d4",
  authDomain: "tethqarblog.firebaseapp.com",
  projectId: "tethqarblog",
  storageBucket: "tethqarblog.appspot.com",
  messagingSenderId: "52910795183",
  appId: "1:52910795183:web:3bbfac507d594b1768675a",
  measurementId: "G-PWZ4SLMKVQ"
};

firebase.initializeApp(config);

export default firebase;
