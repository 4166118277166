import { Col, Row } from "react-bootstrap";
import MobilesPhoto from "../../assets/mobiles2.png";
import NumeroLogo from "../../assets/numero-2.png";
import useStyles from "./Nstyles";
import Line from "../../assets/line.png";
import Aos from "aos";

import { useEffect } from "react";
const NumeroFeatures = (props) => {
  const { ln } = props;
  const classes = useStyles();

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="container-fluid">
        {ln == "en" ? (
          <Row>
            <Col xs={12} md={7} lg={7} className="p-5">
              <h1 className="hb1 m-3">OFFERED FEATURES</h1>

              <img src={Line} className="m-3 mt-0" />
              <ul>
                <li> Internet Connectivity.</li>
                <li>
                  2G/3G/4G/LTE connectivity mobile data in 150+ countries.
                </li>
                <li> Calls and SMS.</li>
                <li> Send/Receive local and international calls & SMS.</li>
                <li> Mobile (GSM) Numbers.</li>
                <li> Mobile, toll free and local from 70+ countries.</li>
                <li> Free Roaming Anywhere.</li>
              </ul>
            </Col>
            <Col
              xs={12}
              md={4}
              lg={4}
              className="p-4"
              data-aos="fade-down"
              data-aos-once="true"
            >
              <img src={MobilesPhoto} className="w-100" />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col></Col>
            <Col
              xs={12}
              md={4}
              lg={4}
              className="p-4"
              data-aos="fade-down"
              data-aos-once="true"
            >
              <img src={MobilesPhoto} className="w-100" />
            </Col>
            <Col xs={12} md={7} lg={7} className="p-5" dir="rtl">
              <h1 className="hb1 m-3 ar1">المزايا المُقدمة</h1>

              <img src={Line} className="mt-0 m-3" />
              <ul dir="rtl" className="ar2">
                <li>إتصال بالإنترنت.</li>
                <li>
                  اقات بيانات للإتصال بحجم <span className="en2">2</span> جيجا /
                  <span className="en2"> 3 </span> جيجا /
                  <span className="en2"> 4 </span> جيجا/ وللإتصالات المتطورة
                  اللاسلكية طويلة الأمد ذات النطاق العريض متوفرة في أكثر من{" "}
                  <span className="en2"> 150 </span>
                  دولة
                </li>
                <li> مكالمات ورسائل نصية. </li>
                <li> إرسال واستلام مكالمات ورسائل نصية محلية ودولية. </li>
                <li> أرقام هاتف جوَّال خلوي. </li>
                <li>
                  خدمة هاتف وأرقام للإتصال المجاني والمحلي من أكثر من
                  <span className="en2">70</span> دولة.
                </li>
                <li> خدمة تجوال مجاني عند الإتصال من أي مكان.</li>
              </ul>
            </Col>
          </Row>
        )}
      </div>
      <div className={classes.div2}>
        <center>
          <img src={NumeroLogo} width={100} className="mb-1" />
        </center>
        {ln == "en" ? (
          <div>
            <p className="text-center">
              With over 8 million downloads, users can easily get a second phone
              number from more than 80 countries without the need for an extra
              SIM card.
            </p>
            <p className="text-center">
              <span className="h3p1">
                <b>"Numero eSIM”</b>
              </span>{" "}
              is the perfect solution for any user who wishes to have 2 phone
              numbers on one mobile device.
            </p>
            <p className="text-center">
              <span className="h3b1">
                <b>“ADVERTIQA”</b>
              </span>{" "}
              believes in the business potential “Numero eSIM” can provide.
            </p>{" "}
            <p className="text-center">
              We have partnered with “Smarteletec S.L.”, the owner company of
              this application, as a super affiliate of “Numero eSIM” mobile app
              to offer their service in many companies in the State of Qatar;
              especially the travel, tourism and marketing sectors."
            </p>
          </div>
        ) : (
          <div dir="rtl" className="">
            <Row>
              <Col xs={12} md={2} lg={2}></Col>
              <Col>
                <p className=" ar2">
                  مع أكثر من <span className="en2">8</span> ملايين عملية تنزيل،
                  يمكن للمستخدمين الحصول على رقم هاتف ثانٍ من أكثر من{" "}
                  <span className="en2">80</span> دولة وبدون الحاجة لشريحة إتصال
                  هاتفية إضافية.
                </p>
                <p className=" ar2">
                  إنه الحل الأمثل لأي مُستخدم يتطلع للحصول على رقمي هاتِفَيْن
                  على جهاز متحرك واحد. تؤمن
                  <span className="h3b1 ar1 bold">
                    <b>"ادفرتيكا "</b>
                  </span>
                  بالقدرة المؤهلة للتطبيق في مجال الأعمال.
                  <br />
                  لقد أقمنا شراكة مع "سمارتيليتيك إس. إل."، الشركة المالكة
                  للتطبيق المذكور، كجهة منتسبة عالية الجودة من شركة
                  <span className="h3p1 ar1 bold">
                    <b>"نوميرو"</b>
                  </span>
                  ، وذلك لتقديم خدماتهما للعديد من الجهات العاملة في دولة قطر،
                  خاصة في قطاعات التسويق والسياحة والسفر.
                </p>
              </Col>
              <Col xs={12} md={2} lg={2}></Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

export default NumeroFeatures;
