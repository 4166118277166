import React from "react";
import "./styles.css";
import TethqarLogo from "../../assets/AdvertiQA Logo White.png";
import ContactUsBG from "../../assets/Contact Us BG.png";
import { MobileView, BrowserView } from 'react-device-detect';
import { Col, Container, Row } from "react-bootstrap";

const ContactUs = (props) => {
  const { ln } = props;
  return (
    <div id="tethqercontact">
        {ln == "en" ? (
          <div className="blue">
            <BrowserView>
            <Row xs={12} md={12} lg={12}>
              <div>

                  <div className="aligncenter">
                    <button className="contactbutton" onClick={() => window.open("https://wa.me/97451127937 ")}>BOOK NOW</button>
                  </div>

                <img src={ContactUsBG} className="contactusframe"/>

              </div>


            </Row>
            <Row
              className="section1 p-0"
              xs={12}
              md={12}
              lg={12}
              data-aos="fade"
              data-aos-once="true"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.02171242598!2d51.51428971447664!3d25.26985503495126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c56074b13687%3A0x951aaf5be4ce076!2sAdvertiqa%20Communications%2C%20Advertising%20%26%20Marketing%20W.L.L.!5e0!3m2!1sen!2sin!4v1638847548856!5m2!1sen!2sin"
                className="w-100 h-100"
                allowfullscreen=""
                loading="eager"
              ></iframe>
            </Row>
            </BrowserView>
            <MobileView>
            <Row xs={12} md={12} lg={12}>
              <div>

                  <div className="aligncenter">
                    <button className="contactbuttonmob" onClick={() => window.open("https://wa.me/97451127937 ")}>BOOK NOW</button>
                  </div>

                <img src={ContactUsBG} className="contactusframe"/>

              </div>


            </Row>
            <Row
              className="section1 p-0"
              xs={12}
              md={12}
              lg={12}
              data-aos="fade"
              data-aos-once="true"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.02171242598!2d51.51428971447664!3d25.26985503495126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c56074b13687%3A0x951aaf5be4ce076!2sAdvertiqa%20Communications%2C%20Advertising%20%26%20Marketing%20W.L.L.!5e0!3m2!1sen!2sin!4v1638847548856!5m2!1sen!2sin"
                className="w-100 h-100"
                allowfullscreen=""
                loading="eager"
              ></iframe>
            </Row>
            </MobileView>
          </div>
        ) : (
          <div className="blue rtl">
            <BrowserView>
            <Row xs={12} md={7} lg={12}>
              <div>

                  <div className="aligncenter">
                    <button className="contactbutton" onClick={() => window.open("https://wa.me/97451127937 ")}>احجز الآن</button>
                  </div>

                <img src={ContactUsBG} className="contactusframe"/>

              </div>


            </Row>
            <Row
              className="section1 p-0"
              xs={12}
              md={6}
              lg={12}
              data-aos="fade"
              data-aos-once="true"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.02171242598!2d51.51428971447664!3d25.26985503495126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c56074b13687%3A0x951aaf5be4ce076!2sAdvertiqa%20Communications%2C%20Advertising%20%26%20Marketing%20W.L.L.!5e0!3m2!1sen!2sin!4v1638847548856!5m2!1sen!2sin"
                className="w-100 h-100"
                allowfullscreen=""
                loading="eager"
              ></iframe>
            </Row>
            </BrowserView>
            <MobileView>
            <Row xs={12} md={7} lg={12}>
              <div>

                  <div className="aligncenter">
                    <button className="contactbuttonmob" onClick={() => window.open("https://wa.me/97451127937 ")}>احجز الآن</button>
                  </div>

                <img src={ContactUsBG} className="contactusframe"/>

              </div>


            </Row>
            <Row
              className="section1 p-0"
              xs={12}
              md={6}
              lg={12}
              data-aos="fade"
              data-aos-once="true"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.02171242598!2d51.51428971447664!3d25.26985503495126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c56074b13687%3A0x951aaf5be4ce076!2sAdvertiqa%20Communications%2C%20Advertising%20%26%20Marketing%20W.L.L.!5e0!3m2!1sen!2sin!4v1638847548856!5m2!1sen!2sin"
                className="w-100 h-100"
                allowfullscreen=""
                loading="eager"
              ></iframe>
            </Row>
            </MobileView>
          </div>
        )}
    </div>
  );
};

export default ContactUs;
