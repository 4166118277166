import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  div2: {
    marginTop: 30,
    padding: 50,
    backgroundColor: "#96153a2d",
  },
  row: {
    backgroundColor: "#ededed",
    padding: 20,
    marginTop: 50,
  },
}));
