import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  head1: {
    color: "#96153A",

    textAlign: "center",
    marginTop: 25,
    marginBottom: 10,
  },
  head2: {
    color: "#96153A",
   
    marginTop:10
  },
  imgCont: {
    borderWidth: 1,
    borderStyle: "solid",
    padding: 50,
    borderColor: "#96153a",
    margin: 4,
    width:300,
    height:315
  },
}));
