import { makeStyles } from "@material-ui/core/styles";


export default makeStyles((theme) => ({
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
  },
  communication: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 70,
    flexDirection: "row",
    flex: 1,
  },
  inquire: {
    backgroundColor: "rgba(0,0,0,0)",
    border: "none",
  },
  quotes: {
    color: "#000",
    textAlign: "center",
    fontWeight: "bold",
  },
}));
