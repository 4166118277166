import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import logo from "../../assets/icon.png";

import Facebook from "../../assets/footer/facebook.png";
import Twitter from "../../assets/footer/twitter.png";
import Instagram from "../../assets/footer/instagram.png";
import Youtube from "../../assets/footer/youtube.png";
import Snapchat from "../../assets/footer/snapchat.png";
import { ReactComponent as Line } from "../../assets/Line.svg";
import LinkedIn from "../../assets/footer/linkedIn.png";

import PhoneIcon from "../../assets/phone.png";
import LocationIcon from "../../assets/location.png";
import Addressbanner from "../../assets/footer/address.jpeg";
import CopyrightBaanner from "../../assets/footer/copyright.jpeg";

import "./styles.css";
import { Snackbar } from "@material-ui/core";
const Footer = (props) => {
  const { ln } = props;
  return (
    <div className="container-fluid">
      {ln == "en" ? (
        <Row>
          <Col xs={12} md={4} lg={4} className="p-3">
            <div className="p-4">
              <img src={logo} className="w-100" />
            </div>
            <br />
            <div className="mt-5">
              <center>
                <h6>
                  <b>Follow us here</b>
                </h6>
                <a href="https://www.facebook.com/advertiqa/">
                  <img src={Facebook} width={40} />
                </a>
                <a href="https://instagram.com/advertiqa_qatar?utm_medium=copy_link">
                  <img src={Instagram} width={40} />
                </a>
                <a href="https://twitter.com/advertiqa?s=21">
                  <img src={Twitter} width={40} />
                </a>
                <a href="https://www.youtube.com/channel/UCVhPVFZ52tg94gLMD_QTblg ">
                  <img src={Youtube} width={40} />
                </a>
                {/* <a>
                  <img src={LinkedIn} width={40} />
                </a>
                <a>
                  <img src={Snapchat} width={40} />
                </a> */}
              </center>
            </div>
          </Col>
          <Col xs={12} md={7} lg={7}>
            <h3 style={{ color: "#96153A" }}>OUR SERVICES</h3>

            <Row>
              <Col>
                <h5 className="h3p1 m-3">Communications</h5>

                <ul>
                  <li>Printing</li>
                  <li>Events</li>
                  <li>Public Relations</li>
                  <li>Copy writing</li>
                </ul>
              </Col>
              <Col>
                <h5 className="h3p1 m-3">Advertising</h5>

                <ul>
                  <li>Print & Digital Advertising</li>
                  <li>Corporate Branding</li>
                  <li>Graphic Design</li>
                  <li>Photography & Videography</li>
                </ul>
              </Col>
              <Col>
                <h5 className="h3p1 m-3">Digital Marketing</h5>

                <ul>
                  <li>Digital Publishing</li>
                  <li>Social Media</li>
                  <li>Website's Development</li>
                  <li>Mobile Phone Apps</li>
                </ul>
              </Col>
            </Row>

            <Row>
              <Col xs={2} lg={1} md={1}>
                <img src={LocationIcon} className="m-2" />
              </Col>
              <Col xs={10} lg={7} md={7}>
                <b>Location</b>
                <br />

                <p className="book" style={{ fontSize: 13.5 }}>
                  Building No. 20, 2nd Floor, Office No. 7, 840 Al Rawabi St,
                  Doha – Qatar
                </p>
              </Col>
              <Col xs={2} lg={1} md={1}>
                <img src={PhoneIcon} className="m-2" />
              </Col>
              <Col xs={10} lg={3} md={3}>
                {ln == "en" ? <b>Contact</b> : <b>اتصل بنا </b>}
                <br />
                <p className="book" style={{ fontSize: 14 }}>
                  {" "}
                  +97444196725
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} md={7} lg={7}>
            <h3
              style={{ color: "#96153A", fontWeight: "bold" }}
              dir="rtl"
              className="ar1"
            >
              خدماتنا
            </h3>

            <Row>
              <Col>
                <h5 className="h3p1 m-3 ar1" dir="rtl">
                  التسويق الالكتروني
                </h5>

                <ul dir="rtl" className="ar2">
                  <li>النشر الرقمي</li>
                  <li>شبكات التواصل الإجتماعي</li>
                  <li>تطوير المواقع على شبكات الإنترنت المختلفة</li>
                  <li>تطبيقات الهواتف الذكية</li>
                </ul>
              </Col>

              <Col>
                <h5 className="h3p1 m-3 ar1" dir="rtl">
                  الإعلان
                </h5>

                <ul dir="rtl" className="ar2">
                  <li>الهويات والشعارات المؤسسية والعلامات التجارية</li>
                  <li>التصميم الغرافيكي</li>
                  <li>التصوير الثابت والمتحرك الفيديو</li>
                </ul>
              </Col>

              <Col>
                <h5 className="h3p1 m-3 ar1" dir="rtl">
                  التواصل
                </h5>

                <ul dir="rtl" className="ar2">
                  <li>الطباعة</li>
                  <li> الفعاليات</li>
                  <li>العلاقات العامة</li>
                  <li>التأليف والتدقيق التحريري</li>
                </ul>
              </Col>
            </Row>

            <Row>
              <Col xs={10} lg={5} md={5}>
                <div dir="rtl">
                  <img src={PhoneIcon} className="m-2" />
                  <b className="ar2">رقم الهاتف </b>
                  <br />
                  <span dir="ltr" style={{ fontSize: 13 }}>
                    +97444196725
                  </span>
                </div>
              </Col>

              <Col xs={10} lg={5} md={5}>
                <div dir="rtl">
                  <img src={LocationIcon} className="m-2" />
                  <b className="ar2">الموقع </b>

                  <br />

                  {/* <p dir="rtl" className="ar2">
                    شارع الروابي 840 المبنى # 20 ، مكتب # 7 الدوحة قطر
                  </p> */}
                  <img src={Addressbanner} className="d-block w-100" />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={4} lg={4} className="p-3">
            <div className="p-4">
              <img src={logo} className="w-100" />
            </div>
            <br />
            <div className="mt-5">
              <center>
                <h6 className="ar2">
                  <b>تابعونا هنا</b>
                </h6>
                <a href="https://www.facebook.com/advertiqa/">
                  <img src={Facebook} width={40} />
                </a>
                <a href="https://instagram.com/advertiqa_qatar?utm_medium=copy_link">
                  <img src={Instagram} width={40} />
                </a>
                <a href="https://twitter.com/advertiqa?s=21">
                  <img src={Twitter} width={40} />
                </a>
                <a href="https://www.youtube.com/channel/UCVhPVFZ52tg94gLMD_QTblg ">
                  <img src={Youtube} width={40} />
                </a>
                {/* <a>
                  <img src={LinkedIn} width={40} />
                </a>
                <a>
                  <img src={Snapchat} width={40} />
                </a> */}
              </center>
            </div>
          </Col>
        </Row>
      )}
      <Row className="footbar p-2 mt-4">
        {ln == "en" ? (
          <center>ADVERTIQA © ALL RIGHTS RESERVED 2022 to 2024</center>
        ) : (
          // <center className="ar2">جميع الحقوق محفوظة 2022© أدفرتيكا </center>
          <center>
            <img src={CopyrightBaanner} width={380} />
          </center>
        )}
      </Row>
    </div>
  );
};

export default Footer;
