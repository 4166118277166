import { Row, Col } from "react-bootstrap";
import ListenIcon from "../../assets/Social Media/listen.png";
import ExecuteIcon from "../../assets/Social Media/execute.png";
import PlanIcon from "../../assets/Social Media/plan.png";
import UnderstandIcon from "../../assets/Social Media/understand.png";
import "../../Styles/fonts.css";

const Grid = (props) => {
  const { ln } = props;
  return (
    <div className="mt-5">
      <div className="container">
        {ln == "en" ? (
          <Row>
            <Col xs={12} md={3} lg={3} className="grid1">
              <div className="p-3">
                <center>
                  <img src={ListenIcon} width={70} />
                </center>

                <h4 className="gHead1 text-center">Watch & Listen</h4>
                <p className="en2">
                  Social viewing and listening to track, analyze and respond to
                  analytics and boost social media engagement.
                </p>
              </div>
            </Col>
            <Col xs={12} md={3} lg={3} className="grid2">
              <div className="p-3">
                <center>
                  <img src={UnderstandIcon} width={70} />
                </center>

                <h4 className="gHead2 text-center">Understand</h4>
                <p className="en2">
                  Understanding the nature, objectives and desired target market
                  of your brand.
                </p>
              </div>
            </Col>
            <Col xs={12} md={3} lg={3} className="grid1">
              <div className="p-3">
                <center>
                  <img src={PlanIcon} width={70} />
                </center>

                <h4 className="gHead1 text-center">Plan</h4>
                <p className="en2">
                  Social Media planning that aligns to & cope with specific
                  business trends, objectives and goals, driven by thorough
                  all-type auditing.
                </p>
              </div>
            </Col>
            <Col xs={12} md={3} lg={3} className="grid2">
              <div className="p-3">
                <center>
                  <img src={ExecuteIcon} width={70} />
                </center>

                <h4 className="gHead2 text-center">Execute</h4>
                <p className="en2">
                  Implementing & embedding the right mix of consecutive content
                  on the right channels at the right times. This may include the
                  proper links & relevant attachments.
                </p>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={12} md={3} lg={3} className="grid2">
              <div className="p-3">
                <center>
                  <img src={ExecuteIcon} width={70} />
                </center>

                <h4 className="gHead2 ar1" dir="rtl">
                  نُنَفِذ
                </h4>

                <p dir="rtl" className="ar2">
                  نقوم بوضع وإدخال والتحديث المُتتالي للمحتوى الصحيح والمناسب،
                  بما يتضمن مرفقات وروابط ذات علاقة، وادراجها في القنوات الصحيحة
                  في الأوقات وحسب الجداول الزمنية المُعدَّة.
                </p>
              </div>
            </Col>
            <Col xs={12} md={3} lg={3} className="grid1">
              <div className="p-3">
                <center>
                  <img src={PlanIcon} width={70} />
                </center>

                <h4 className="gHead1 ar1" dir="rtl">
                  نُخَطط
                </h4>

                <p dir="rtl" className="ar2">
                  نُقدم آليات في شبكات التواصل الإجتماعية تواكب وَتُساير وتفي
                  بالأهداف التجارية المحددة لجهة عاملة ما، وذلك من خلال عمليات
                  التدقيق بكل انواعها .
                </p>
              </div>
            </Col>
            <Col xs={12} md={3} lg={3} className="grid2">
              <div className="p-3">
                <center>
                  <img src={UnderstandIcon} width={70} />
                </center>

                <h4 className="gHead2 ar1" dir="rtl">
                  نفهم
                </h4>

                <p dir="rtl" className="ar2">
                  نعمل على الفهم العميق لطبيعة وخصائص منتجاتك وخدماتك واحتياجاتك
                  وأهدافك وعليه لنقوم بتحديد الأسواق والقطاعات الإستهلاكية
                  المرجو إستهدافها .
                </p>
              </div>
            </Col>
            <Col xs={12} md={3} lg={3} className="grid1">
              <div className="p-3">
                <center>
                  <img src={ListenIcon} width={70} />
                </center>

                <h4 className="gHead1 ar1" dir="rtl">
                  نشاهد ونسمع
                </h4>
                <p dir="rtl" className="ar2">
                  نقوم بإجراء عمليات الإستماع والمشاهدة الإجتماعية وذلك لرصد،
                  تحليل والرد على التحليلات والملاحظات والبيانات الموجودة
                  كالتعليقات والتفاعلات والمواد المرئية والأجوبة، للدفع لزيادة
                  معدلات تفاعل ومشاركة شبكات التواصل الإجتماعي .
                </p>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default Grid;
