import React, { useState } from "react";
import logo from "../../assets/icon.png";
import useStyles from "./styles";
import { Navbar, Nav, Container, NavDropdown, Row, Col } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

const Navbars = (props) => {
  const { ln } = props;
  const classes = useStyles();

  const [left1, setLeft1] = useState(0);

  const CarouselUI = ({ children }) => <Container>{children}</Container>;

  return (
    <div>
      {ln == "en" ? (
        <Navbar bg="white" variant="light" expand="lg" style={{ zIndex: 4 }}>
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} style={{ width: 150 }} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto navbar-transparent">
                <NavHashLink to="/" className="nav-link">
                  HOME
                </NavHashLink>
                <NavHashLink to="/#about" className="nav-link">
                  ABOUT US
                </NavHashLink>
                <NavHashLink to="/#services" className="nav-link">
                  OUR SERVICES
                </NavHashLink>
                <NavHashLink to="/#projects" className="nav-link">
                  OUR PROJECTS
                </NavHashLink>
                <NavHashLink to="/#contact" className="nav-link">
                  CONTACT US
                </NavHashLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      ) : (
        <Navbar bg="white" variant="light" expand="lg" style={{ zIndex: 4 }}>
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto navbar-transparent ar2" dir="rtl">
                <NavHashLink to="/" className="nav-link">
                  الرئيسية
                </NavHashLink>
                <NavHashLink className="nav-link" to="/#about">
                  من نحن
                </NavHashLink>
                <NavHashLink className="nav-link" to="/#services">
                  خدماتنا
                </NavHashLink>
                <NavHashLink className="nav-link" to="/#projects">
                  مشاريعنا
                </NavHashLink>
                <NavHashLink className="nav-link" to="/#contact">
                  للتواصل معنا
                </NavHashLink>
              </Nav>
            </Navbar.Collapse>

            <Navbar.Brand to="/">
              <img src={logo} style={{ width: 150 }} />
            </Navbar.Brand>
          </Container>
        </Navbar>
      )}
    </div>
  );
};

export default Navbars;
