import { Row, Col } from "react-bootstrap";
import MobilesPic from "../../assets/mobile-2.jpg";
const Profile = () => {
  return (
    <div className="container-fluid">
      <img src={MobilesPic} className="w-100" />
    </div>
  );
};

export default Profile;
