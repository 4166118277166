
import { Row, Col } from "react-bootstrap";
import React from "react";
import ServicesHeading from "../../assets/Conversion Services Heading.png";
import VHS from "../../assets/TethqarProducts/VHS.png";
import VIDEO8_HI8 from "../../assets/TethqarProducts/Hi8&Video8.png";
import LASERDISK from "../../assets/TethqarProducts/Laser Disc.png";
import BETAMAX from "../../assets/TethqarProducts/Betamax.png";
import FLOPPYDISK from "../../assets/TethqarProducts/Floppy Disk.png";
import MINICD_DVD from "../../assets/TethqarProducts/MiniCD.png";
import AUDIOCASSETTE from "../../assets/TethqarProducts/Audio Cassette.png";
import CD_DVD from "../../assets/TethqarProducts/CD&DVD.png";
import MINIDV_HDV from "../../assets/TethqarProducts/MiniDV.png";
import VHS_C from "../../assets/TethqarProducts/VHSC.png";
import "./styles2.css";


const TethqarProducts = (props) => {
  const { ln } = props;

  return (
    <div id="tethqerservices" className="servicebackground">

        <Row>

          <center>
          <img src={ServicesHeading} className="servicehead w-50"/>
          </center>

        </Row>

      <Row className=" p-0 m-0 grid-container w-100">
        <Col xs={4} md={3} lg={2} className="grid-item">
          <img src={VHS}/>
        </Col>      
        <Col xs={4} md={3} lg={2} className="grid-item">
            <img src={BETAMAX}/>
        </Col>
        <Col xs={4} md={3} lg={2} className="grid-item">
            <img src={VIDEO8_HI8} />
        </Col>
        <Col xs={4} md={3} lg={2} className="grid-item">
            <img src={VHS_C}/>
        </Col>
        <Col xs={4} md={3} lg={2} className="grid-item">
            <img src={MINIDV_HDV} />
        </Col>
        <Col xs={4} md={3} lg={2} className="grid-item">
            <img src={AUDIOCASSETTE}/>
        </Col>
        <Col xs={4} md={3} lg={2} className="grid-item">
            <img src={MINICD_DVD} />
        </Col>
        <Col xs={4} md={3} lg={2} className="grid-item">
            <img src={CD_DVD} />
        </Col>       
        <Col xs={4} md={3} lg={2} className="grid-item">
            <img src={FLOPPYDISK}/>
        </Col>
        <Col xs={4} md={3} lg={2} className="grid-item">
            <img src={LASERDISK} />
        </Col>
      </Row>
    </div>

  );
};

export default TethqarProducts;