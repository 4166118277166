import React from "react";
import "./styles.css";
import PromosLogo from "../../assets/promos-logo-2.png";
import AppStore from "../../assets/app-store.png";
import PlayStore from "../../assets/google-play.png";
import { Col, Row } from "react-bootstrap";
import QrCode from "../../assets/qr-promos.png";

const index = (props) => {
  const { ln } = props;
  return (
    <div className="banner">
      <div className="container">
        {ln == "en" ? (
          <Row>
            <Col xs={12} md={3} lg={3} className="p-5">
              <img src={PromosLogo} className="w-100" />
            </Col>
            <Col xs={12} md={7} lg={7}>
              <div className="p-4 pt-5">
                <h1 className="head1">Promos.Qa</h1>

                <p className="para">
                  “Promos.Qa” is an advertising mobile application that is
                  available in IOS and Android systems. It allows users to get
                  notified on deals and new arrivals of their favorite outlets
                  and brands in Qatar. Through a feed of curated lists, users
                  will be able to receive notifications based on their chosen
                  interests.
                </p>
              </div>
              <br />
              <Row>
                <Col xs={6} md={6} lg={3}>
                  <a href="https://apps.apple.com/gb/app/promos-qa/id1472475541">
                    <img src={AppStore} className="d-block w-100 mt-2" />
                  </a>
                </Col>
                <Col xs={6} md={6} lg={3}>
                  <a href="https://play.google.com/store/apps/details?id=com.promoqatar.shop">
                    <img src={PlayStore} className="d-block w-100 mt-2" />
                  </a>
                </Col>
                <Col xs={12} md={12} lg={3}>
                  <button
                    className="btn bn"
                    style={{
                      borderWidth: 2,
                      borderColor: "#96153a",
                      color: "#96153a",
                      fontWeight: "bold",
                      height: 42,
                      marginTop: 35,
                      fontSize: 13,
                    }}
                    onClick={() => window.open("https://www.Promos.Qa ")}
                  >
                    VISIT WEBSITE
                  </button>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={2} lg={2}>
              {/* <img src={QrCode} className="d-block w-100 mt-5" /> */}
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={12} md={2} lg={2}>
              {/* <img src={QrCode} className="d-block w-100 mt-5" /> */}
            </Col>
            <Col xs={12} md={7} lg={7}>
              <h1 className="head1 ar1" dir="rtl">
                بروموس كْيُو. إيه
              </h1>

              <p className="para ar2" dir="rtl">
                تطبيق للهواتف المتحركة خاص بالإعلانات. متوفر في أنظمة التشغيل
                الخاصة بهواتف "آيفون" وأنظمة التشغيل آندرويد للهواتف الأخرى.
                يُساعد التطبيق مستعملي الهواتف الذكية والحواسيب اللوحية على
                استلام الإشعارات الخاصة بآخر العروض التجارية ووصول المنتجات
                لمتاجرهم المُفضلة وعلاماتهم التجارية في دولة قطر. سيكون في وسع
                مستخدمي الهواتف القدرة على تلقي الإشعارات بناءاً على إختياراتهم
                المُفضلة من خلال موجز القوائم المُنَسَّقة .
              </p>

              <br />
              <Row>
                <Col xs={6} md={3} lg={3}>
                  <a href="https://apps.apple.com/gb/app/promos-qa/id1472475541">
                    <img src={AppStore} className="d-block w-100" />
                  </a>
                </Col>
                <Col xs={6} md={3} lg={3}>
                  <a href="https://play.google.com/store/apps/details?id=com.promoqatar.shop">
                    <img src={PlayStore} className="d-block w-100" />
                  </a>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <button
                    className="btn bn mt-4 ar2"
                    style={{
                      borderWidth: 2,
                      borderColor: "#96153a",
                      color: "#96153a",
                      fontWeight: "bold",
                      height: 42,
                      fontSize: 13,
                    }}
                    onClick={() => window.open("https://www.Promos.Qa ")}
                  >
                    الموقع الإلكتروني
                  </button>
                </Col>
              </Row>
            </Col>

            <Col xs={12} md={3} lg={3} className="p-5">
              <img src={PromosLogo} className="w-100" />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default index;
