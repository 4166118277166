import React, { useState } from "react";
import About from "../../components/About";
import Mission from "../../components/Mission";
import Values from "../../components/values";
import Profile from "../../components/Relations/Profile";
import Relations from "../../components/Relations";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footer";
import Contact from "../../components/Contact";

const PublicRelation = (props) => {
  const { ln } = props;
  return (
    <div>
      <Navbar ln={ln} />
      <Profile />
      <About ln={ln} />
      <Mission ln={ln} />
      <Values ln={ln} />
      <Relations ln={ln} />
      <Contact ln={ln} />
      <Footer ln={ln} />
    </div>
  );
};

export default PublicRelation;
