import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  head1: {
    color: "#056292",
    
  },
  div2: {
    marginTop: 30,
    padding: 60,
    backgroundColor:'#c7e0ed'
    
  },
}));
