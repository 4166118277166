import React, { useEffect } from "react";
import { Carousel, Button, Container, Row, Col } from "react-bootstrap";
import Line from "../../assets/line2.png";
import PromosLogo from "../../assets/promos-logo-2.png";
import TethqarLogo from "../../assets/Tethqar_logo.png";
import NumeroLogo from "../../assets/numero-2.png";
import useStyles from "./styles";
import "./styles2.css";
import { Link } from "react-router-dom";
import Aos from "aos";

const Products = (props) => {
  const { ln } = props;
  const classes = useStyles();
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="container">
      <center>
        {ln == "en" ? (
          <h1 className="hp1">OUR PRODUCTS</h1>
        ) : (
          <h1 className="hp1 ar1">منتجاتنا</h1>
        )}
      </center>

      <center>
        <img src={Line} />
      </center>
      <center>
        {ln == "en" ? (
          <Row>
            <Col></Col>
            <Col xs={12} md={3} lg={3}>
              <Link to="/Promos">
                <div className="promos" data-aos="fade" data-aos-once="true">
                  <center>
                    <img src={PromosLogo} className="w-100" />
                  </center>
                </div>
              </Link>
            </Col>

            <Col xs={12} md={3} lg={3}>
              <Link to="/Numero">
                <div className="promos" data-aos="fade" data-aos-once="true">
                  <center>
                    <img src={NumeroLogo} className="w-100" />
                  </center>
                </div>
              </Link>
            </Col>

            <Col xs={12} md={3} lg={3}>
              <Link to="/Tethqar">
                <div className="tethqar" data-aos="fade" data-aos-once="true">
                  <center>
                    <img src={TethqarLogo} className="w-100" />
                  </center>
                </div>
              </Link>
            </Col>
            <Col></Col>

            {/* <Col xs={12} md={12} lg={6} className="p-4">
            {ln == "en" ? (
              <p className={classes.para}>
                <span style={{ color: "#96153A" }}>“Numero eSIM”</span> is the
                perfect solution for any user who wishes to have 2 phone numbers
                on one mobile device.
                <br />
                <br />
                <span style={{ color: "#056292" }}>“ADVERTIQA”</span> believes
                in the business potential “Numero eSIM” can provide.
                <br />
                <br />
                We have partnered with “Smarteletec S.L.”, the owner company of
                this application, as a super affiliate of “Numero eSIM” mobile
                app to offer their service in many companies in the State of
                Qatar; especially the travel, tourism and marketing sectors.
              </p>
            ) : (
              <p className={classes.para} dir="rtl">
                إنه الحل الأمثل لأي مُستخدم يتطلع للحصول على رقمي هاتِفَيْن على
                جهاز متحرك واحد. تؤمن "أدفيرتكا" بالقدرة المؤهلة للتطبيق في مجال
                الأعمال. لقد أقمنا شراكة مع "سمارتيليتيك إس. إل."، الشركة
                المالكة للتطبيق المذكور، كجهة منتسبة عالية الجودة من شركة
                "نوميرو"، وذلك لتقديم خدماتهما للعديد من الجهات العاملة في دولة
                قطر، خاصة في قطاعات التسويق والسياحة والسفر
              </p>
            )}
          </Col> */}
          </Row>
        ) : (
          <Row>
            <Col></Col>
            <Col xs={12} md={3} lg={3}>
              <Link to="/Promos">
                <div className="promos" data-aos="fade" data-aos-once="true">
                  <center>
                    <img src={PromosLogo} className="w-100" />
                  </center>
                </div>
              </Link>
            </Col>

            <Col xs={12} md={3} lg={3}>
              <Link to="/Numero">
                <div className="promos" data-aos="fade" data-aos-once="true">
                  <center>
                    <img src={NumeroLogo} className="w-100" />
                  </center>
                </div>
              </Link>
            </Col>

            <Col xs={12} md={3} lg={3}>
              <Link to="/Tethqar">
                <div className="promos" data-aos="fade" data-aos-once="true">
                  <center>
                    <img src={TethqarLogo} className="w-100" />
                  </center>
                </div>
              </Link>
            </Col>
            <Col></Col>

            {/* <p className={classes.para} dir="rtl">
                إنه الحل الأمثل لأي مُستخدم يتطلع للحصول على رقمي هاتِفَيْن على
                جهاز متحرك واحد. تؤمن "أدفيرتكا" بالقدرة المؤهلة للتطبيق في مجال
                الأعمال. لقد أقمنا شراكة مع "سمارتيليتيك إس. إل."، الشركة
                المالكة للتطبيق المذكور، كجهة منتسبة عالية الجودة من شركة
                "نوميرو"، وذلك لتقديم خدماتهما للعديد من الجهات العاملة في دولة
                قطر، خاصة في قطاعات التسويق والسياحة والسفر
              </p> */}
          </Row>
        )}
      </center>
    </div>
  );
};

export default Products;
