import { Col, Row } from "react-bootstrap";
import MobilesPhoto from "../../assets/mobiles2.png";
import photo1 from "../../assets/processimg1.png";
import photo2 from "../../assets/processimg2.png";
import photo3 from "../../assets/processimg3.png";
import Tethqar_logo from "../../assets/Tethqar_logo.png";
import useStyles from "./Nstyles";
import Line from "../../assets/line.png";
import { MobileView, BrowserView } from 'react-device-detect';
import Aos from "aos";
import "./styles.css";

import { useEffect } from "react";
const TethqarFeatures = (props) => {
  const { ln } = props;
  const classes = useStyles();

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div id="tethqerprocess" className="bg">
      <div className="container-fluid p-0 m-0">
        {ln == "en" ? (
          <div>

            <BrowserView>

            <Row>
              <h1 className="heading" style={{
                  marginTop: 30,
                  marginBottom: 20,
                  fontSize: '70px' // Adjust the font size as needed
              }}>PROCESS</h1>
          </Row>
          <Row>
            <Col
              xs={12} md={4} lg={6} className="p-4"
              data-aos="fade-down"
              data-aos-once="true">
              <img src={photo1} className="w-100" />
            </Col>
            <Col xs={12} md={7} lg={6} className="p-5">
                  <div className="text2">
                    <div className="pt-50">
                      <h1 className="headtext2">COLLECT</h1>
                  <p className="text2"> Gather all your precious materials that
                      require conversion to digital formats &
                      schedule an appointment to drop them off
                      at our office.</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>

            <Col xs={12} md={7} lg={6} className="p-5">
                  <div className="text2">
                    <div className="pt-50">
                      <h1 className="headtext2">DROP OFF</h1>
                  <p className="text2"> Drop off the materials during your
                      scheduled appointment. Our team will
                      safely collect your precious materials &
                      storage drive. USBs & Hard Drives are
                      also availale for purchase.</p>
                </div>
              </div>
            </Col>
            <Col
              xs={12} md={4} lg={6} className="p-4"
              data-aos="fade-down"
              data-aos-once="true">
              <img src={photo2} className="w-100" />
            </Col>
          </Row>
          <Row>
            <Col
              xs={12} md={4} lg={6} className="p-4"
              data-aos="fade-down"
              data-aos-once="true">
              <img src={photo3} className="w-100" />
            </Col>
            <Col xs={12} md={7} lg={6} className="p-5">
                  <div className="text2">
                    <div className="pt-50">
                      <h1 className="headtext2">RELIVE</h1>
                  <p className="text2"> Once materials have been converted, you
                  will be notified to collect your CONVERTED
                  materials. Rest assured, your memories
                  have been safely preserved for years to
                  come.</p>
                </div>
              </div>
            </Col>
          </Row>
          </BrowserView>

          <MobileView>
          <Row>
              <h1 className="heading" style={{
                  marginTop: 30,
                  marginBottom: 20,
                  fontSize: '50px' // Adjust the font size as needed
              }}>PROCESS</h1>
          </Row>


            <Row
              xs={12} md={4} lg={6}
              data-aos="fade-down"
              data-aos-once="true">
              <img src={photo1} className="w-100" />
            </Row>
            <Row xs={12} md={7} lg={6} className="p-3">
                  <div className="text2">
                    <div className="pt-50">
                      <h1 className="headtext2">COLLECT</h1>
                  <p className="text2"> Gather all your precious materials that
                      require conversion to digital formats &
                      schedule an appointment to drop them off
                      at our office.</p>
                </div>
              </div>
            </Row>
          <Row
              xs={12} md={4} lg={6}
              data-aos="fade-down"
              data-aos-once="true">
              <img src={photo2} className="w-100" />
            </Row>
            <Row xs={12} md={7} lg={6} className="p-3">
                  <div className="text2">
                    <div className="pt-50">
                      <h1 className="headtext2">DROP OFF</h1>
                  <p className="text2"> Drop off the materials during your
                      scheduled appointment. Our team will
                      safely collect your precious materials &
                      storage drive. USBs & Hard Drives are
                      also availale for purchase.</p>
                </div>
              </div>
            </Row>

          <Row
              xs={12} md={4} lg={6}
              data-aos="fade-down"
              data-aos-once="true">
              <img src={photo3} className="w-100" />
            </Row>
            <Row xs={12} md={7} lg={6} className="p-3">
                  <div className="text2">
                    <div className="pt-50">
                      <h1 className="headtext2">RELIVE</h1>
                  <p className="text2"> Once materials have been converted, you
                  will be notified to collect your CONVERTED
                  materials. Rest assured, your memories
                  have been safely preserved for years to
                  come.</p>
                </div>
              </div>
            </Row>
          </MobileView>
          </div>
          
        ) : (
          <div className="rtl">

              <BrowserView>
                <Row>
                    <h1 className="heading" style={{
                        marginTop: 30,
                        marginBottom: 20,
                        fontSize: '70px' 
                      }}>خطوات / مسارالعمل</h1>
                </Row>
              <Row>
                <Col
                  xs={12} md={4} lg={6} className="p-4"
                  data-aos="fade-down"
                  data-aos-once="true">
                  <img src={photo1} className="w-100" />
                </Col>
                <Col xs={12} md={7} lg={6} className="p-5">
                  <div className="text2">
                    <div className="pt-50">
                      <h1 className="headtext2arb">جمع الأشرطة</h1>
                      <p className="text2arb"> قم بجمع كافة الأشرطة المراد تحويلها إلى وسائط رقمية واحجز موعدك لتسليم الأشرطة لمكتبنا</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
    
                <Col xs={12} md={7} lg={6} className="p-5">
                  <div className="text2">
                    <div className="pt-50">
                      <h1 className="headtext2arb">تسليم الأشرطة</h1>
                      <p className="text2arb"> قم بتسليم الأشرطة في الموعد الذي تم تحديده. سيضمن فريق عملنا استلام أشرطتك الثمينة وأقراص التخزين بعناية وحرص. يتوفر لدينا فلاش (USB) أو أقراص صلبة للبيع.</p>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12} md={4} lg={6} className="p-4"
                  data-aos="fade-down"
                  data-aos-once="true">
                  <img src={photo2} className="w-100" />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12} md={4} lg={6} className="p-4"
                  data-aos="fade-down"
                  data-aos-once="true">
                  <img src={photo3} className="w-100" />
                </Col>
                <Col xs={12} md={7} lg={6} className="p-5">
                  <div className="text2">
                    <div className="pt-50">
                      <h1 className="headtext2arb">استعادة استرجاع الذكريات</h1>
                      <p className="text2arb"> بعد أن يتم تحويل الأشرطة، سيتم التواصل معك لاستلام الوسائط. اطمئن .. أصبح الآن من السهل الاحتفاظ والاستمتاع بمشاهدة ذكرياتك الثمينة لأعوام قادمة.</p>
                    </div>
                  </div>
                </Col>
              </Row>
              </BrowserView>
              <MobileView>
              <Row>
                    <h1 className="heading" style={{
                        marginTop: 30,
                        marginBottom: 20,
                        fontSize: '50px' 
                      }}>خطوات / مسارالعمل</h1>
                </Row>
              <Row
                  xs={12} md={4} lg={6}
                  data-aos="fade-down"
                  data-aos-once="true">
                  <img src={photo1} className="w-100" />
                </Row>
                <Row xs={12} md={7} lg={6} className="p-5">
                  <div className="text2">
                    <div className="pt-50">
                      <h1 className="headtext2arb">جمع الأشرطة</h1>
                      <p className="text2arb"> قم بجمع كافة الأشرطة المراد تحويلها إلى وسائط رقمية واحجز موعدك لتسليم الأشرطة لمكتبنا</p>
                    </div>
                  </div>
                </Row>

              <Row
                  xs={12} md={4} lg={6}
                  data-aos="fade-down"
                  data-aos-once="true">
                  <img src={photo2} className="w-100" />
                </Row>
                <Row xs={12} md={7} lg={6} className="p-5">
                  <div className="text2">
                    <div className="pt-50">
                      <h1 className="headtext2arb">تسليم الأشرطة</h1>
                      <p className="text2arb"> قم بتسليم الأشرطة في الموعد الذي تم تحديده. سيضمن فريق عملنا استلام أشرطتك الثمينة وأقراص التخزين بعناية وحرص. يتوفر لدينا فلاش (USB) أو أقراص صلبة للبيع.</p>
                    </div>
                  </div>
                </Row>

              <Row
                  xs={12} md={4} lg={6}
                  data-aos="fade-down"
                  data-aos-once="true">
                  <img src={photo3} className="w-100" />
                </Row>
              <Row xs={12} md={7} lg={6} className="p-5">
                  <div className="text2">
                    <div className="pt-50">
                      <h1 className="headtext2arb">استعادة استرجاع الذكريات</h1>
                      <p className="text2arb"> بعد أن يتم تحويل الأشرطة، سيتم التواصل معك لاستلام الوسائط. اطمئن .. أصبح الآن من السهل الاحتفاظ والاستمتاع بمشاهدة ذكرياتك الثمينة لأعوام قادمة.</p>
                    </div>
                  </div>
                </Row>



          </MobileView>
              </div>
        )}
      </div>
    </div>
  );
};

export default TethqarFeatures;
