import "../../Styles/fonts.css";
import IconSet from "../../assets/Social Media/icons.png";
import Line from "../../assets/line.png";
import "./styles.css";
import "../../Styles/fonts.css";
const Marketing = (props) => {
  const { ln } = props;
  return (
    <div>
      {ln == "en" ? (
        <h1 className="hb1 mt-5 text-center">Social Media Marketing</h1>
      ) : (
        <h1 className="hb1 mt-5 text-center ar1" dir="rtl">
          التسويق على شبكات التواصل الإجتماعي
        </h1>
      )}

      <center>
        <img src={IconSet} className="icons" />

        {ln == "en" ? (
          <h3 style={{ color: "#000" }} className="mt-2">
            OUR PROCESS
          </h3>
        ) : (
          <h3 style={{ color: "#000" }} className="mt-2 ar1" dir="rtl">
            التسلسل في عملياتنا
          </h3>
        )}
      </center>
    </div>
  );
};

export default Marketing;
