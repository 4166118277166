import useStyles from "./styles";
import { Row, Col } from "react-bootstrap";
import FashionMan from "../../assets/CATEGORY_SERVICES/FASHION MEN/pic.jpg";
import FashionWoman from "../../assets/CATEGORY_SERVICES/FASHION WOMEN/pic.jpg";
import Automotive from "../../assets/CATEGORY_SERVICES/AUTOMOTIVE/pic.jpg";
import Electronics from "../../assets/CATEGORY_SERVICES/ELECTRONIC/pic.jpg";
import Food from "../../assets/CATEGORY_SERVICES/F&B//pic.jpg";
import SportFitness from "../../assets/CATEGORY_SERVICES/SPORTS/pic.jpg";
import FashionKids from "../../assets/CATEGORY_SERVICES/FASHION KIDS/pic.jpg";
import Perfumes from "../../assets/CATEGORY_SERVICES/PERFUMES&COSMETICS/pic.jpg";
import Banking from "../../assets/CATEGORY_SERVICES/BANKING/pic.jpg";
import Medical from "../../assets/CATEGORY_SERVICES/MEDICAL/pic.jpg";
import HyperMarket from "../../assets/CATEGORY_SERVICES/HYPERMARKETS/pic.jpg";
import Events from "../../assets/CATEGORY_SERVICES/EVENTS&ENTERTAINMENT/pic.jpg";
import BeutySpa from "../../assets/CATEGORY_SERVICES/BEAUTY & SPA/pic.jpg";
import HomeBusiness from "../../assets/CATEGORY_SERVICES/HOME BUSINESSES/pic.jpg";
import Travel from "../../assets/CATEGORY_SERVICES/TRAVEL&TOURS/pic.jpg";
import Watch from "../../assets/CATEGORY_SERVICES/WATCHES&JEWELRY/pic.jpg";
import Furnitue from "../../assets/CATEGORY_SERVICES/FURNITURES & APPLIANCES/pic.jpg";
import Line from "../../assets/line2.png";
import PromosLogo from "../../assets/promos-logo-2.png";
import "./styles2.css";

const CategoryServices = (props) => {
  const { ln } = props;
  const classes = useStyles();

  const Album = (props) => {
    const { image, text, text2 } = props;
    return (
      <Col xs={6} lg={2} md={3}>
        <div>
          <center>
            <img src={image} className="album" />
          </center>

          {ln == "en" ? (
            <h5
              style={{
                color: "#888",
                textAlign: "center",
                marginTop: 30,
                marginBottom: 20,
              }}
              className="en2"
            >
              {text}
            </h5>
          ) : (
            <h5
              style={{
                color: "#888",
                textAlign: "center",
                marginTop: 30,
                marginBottom: 20,
              }}
              className="ar2"
            >
              {text2}
            </h5>
          )}
        </div>
      </Col>
    );
  };

  return (
    <div>
      <div className="container-fluid p-5 mt-5">
        {ln == "en" ? (
          <h1 className="hp1">CATEGORY SERVICES</h1>
        ) : (
          <h1 className="hp1 ar1" dir="rtl">
            فئات الخدمات
          </h1>
        )}
        <center>
          <img src={Line} />
        </center>
        {ln == "en" ? (
          <h5 className="text-center mt-3 en2">
            App advertisements support promotional videos and multiple images to
            widen the reach of the offers and new arrival of stores.
          </h5>
        ) : (
          <h5 className="text-center mt-3 ar2" dir="rtl">
            توفر خدمة الإعلانات بالتطبيق المذكور فرصة عرض المواد المرئية
            (الفيديو) الترويجية والعديد من الصور، وذلك للتوسع في عملية الوصول
            إلى اخر عروض ومعروضات المتاجر .
          </h5>
        )}
        <div>
          <Row className={classes.row}>
            <Album
              image={FashionMan}
              text={"Fashion - Men"}
              text2={" موضة وأزياء رجال"}
            />
            <Album
              image={FashionWoman}
              text={"Fashion - Women"}
              text2={"موضة وأزياء سيدات"}
            />
            <Album image={Automotive} text={"Automotive"} text2={"سيارات"} />
            <Album
              image={Electronics}
              text={"Electronics"}
              text2={"كهربائيات ومنتجات ألكترونية"}
            />
            <Album
              image={Food}
              text={"Food & Beverage"}
              text2={"مأكولات ومشروبات"}
            />
            <Album
              image={SportFitness}
              text={"Sport & Fitness"}
              text2={"رياضة ولياقة بدنية"}
            />
          </Row>

          <Row className={classes.row}>
            <Album
              image={FashionKids}
              text={"Fashion - Kids"}
              text2={"موضة وأزياء أطفال"}
            />
            <Album
              image={Perfumes}
              text={"Perfumes & Cosmetics"}
              text2={"عطور ومساحيق تجميل"}
            />
            <Album
              image={Banking}
              text={"Banking & Insurance"}
              text2={"مصارف وتأمين"}
            />
            <Album
              image={Medical}
              text={"Medicine & HealthCare"}
              text2={"طب ورعاية صحية"}
            />
            <Album
              image={HyperMarket}
              text={"Hypermarket & Groceries"}
              text2={"مراكز التسوق التجارية الكبيرة والمتوسطة"}
            />
            <Album
              image={Events}
              text={"Events & Entertainment"}
              text2={"فعاليات ونشاطات ترفيهيه"}
            />
          </Row>
          <Row className={classes.row}>
            <Album
              image={BeutySpa}
              text={"Beauty & Spa"}
              text2={"جمال وعناية صحية ومنتجع صحي (سبا)"}
            />
            <Album
              image={Watch}
              text={"Watches & Jewelry"}
              text2={"ساعات ومجوهرات"}
            />
            <Album
              image={Travel}
              text={"Travel & Tourism"}
              text2={"سفر وسياحة"}
            />
            <Album
              image={Furnitue}
              text={"Furniture & Home Appliances"}
              text2={"مفروشات وأجهزة منزلية"}
            />
            <Album
              image={HomeBusiness}
              text={"Home Business"}
              text2={"العمل من المنزل"}
            />
          </Row>
        </div>
      </div>
      <div className="container-fluid ">
        <div className={classes.div2}>
          <center>
            <img src={PromosLogo} width={100} className="mb-2" />
          </center>
          {ln == "en" ? (
            <h6 className="text-center en2">
              “Promos.Qa” aims at building a mutually beneficial partnership,
              which puts relevant information in the hands of thousands of
              potential customers, that in turn rewards promoters by increasing
              attraction and encouraging customer loyalty.
            </h6>
          ) : (
            <h6 className="text-center ar2" dir="rtl">
              يهدف التطبيق إلى بناء شراكة منفعية ثنائية متبادلة، بتُوفير وتقديم
              المعلومات المنشودة ووضعها في متناول العملاء المحتملين، والذين
              يقومون بالتالي بمكافأة المُرَوجين، من خلال رفع مستويات جذب العملاء
              وتحفيز ورفع درجات ولائهم .
            </h6>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryServices;
