import { React, useEffect } from "react";
import {
  Carousel,
  Button,
  Container,
  Row,
  Col,
  ButtonGroup,
} from "react-bootstrap";
import DigitalPublishing from "../../assets/digital.png";
import MobilePhoto from "../../assets/mobile.jpg";
import TouchingPhoto from "../../assets/touching.jpg";
import useStyles from "./styles";
import "./styles2.css";
import { Link } from "react-router-dom";
import Aos from "aos";

const Publishing = (props) => {
  const { ln } = props;
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const classes = useStyles();
  return (
    <div className="container-fluid">
      <div className="publish">
        <center>
          {ln == "en" ? (
            <Row>
              <Col
                xs={12}
                md={5}
                lg={5}
                className="p-5"
                data-aos="fade-down"
                data-aos-once="true"
              >
                <img src={DigitalPublishing} className="d-block w-100" />

                <Button
                  className="btn btn-light "
                  style={{
                    borderWidth: 2,
                    background: "none",
                    color: "#96153A",
                    borderColor: "#96153a",
                    marginTop: 30,
                  }}
                  onClick={() => window.open("http://digital.advertiqa.com/")}
                >
                  CROSS PLATFORM SUPPORT
                </Button>
              </Col>

              <Col xs={12} md={7} lg={7} className="p-5">
                <h1 className={classes.head1}>Digital Publishing</h1>
                <h5 className={classes.head2}>What is Digital Publishing?</h5>
                <p className={classes.para}>
                  A unique, cost-effective service offered by ADVERTIQA to
                  provide innovative digital solutions, which are customized for
                  the client’s marketing strategy that are meant to reach their
                  target audience through digital advertorial newspaper,
                  magazine or publication using latest digital technology.
                </p>
                <h5 className={classes.head2}>Why publish with us ?</h5>
                <p className={classes.para}>
                  Our experienced team will ensure the assigned project is
                  completed while satisfying the clients’ needs and fulfilling
                  their objectives. This may include content creation, creative
                  design,production distribution, social media production and
                  campaign results assessment.
                </p>
                <p className={classes.para}>
                  Our service is equipped with cutting-edge technological
                  applications that allow us to generate reliable results
                  (powered by Google Analytics) to quantify online traffic,
                  number of viewers, rates of viewership, and online platform
                  original sources, (e.g.; E-mail, WhatsApp, Facebook, Twitter,
                  LinkedIn, Instagram).
                </p>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={12} md={7} lg={7} className="p-5">
                <h1
                  className="h3p1 ar1"
                  style={{ textAlign: "right" }}
                  dir="rtl"
                >
                  النشر الرقمي
                </h1>
                <h5 className="ar1" style={{ textAlign: "right" }} dir="rtl">
                  ما هو النشر الرقمي ؟
                </h5>
                <p className="ar2" style={{ textAlign: "right" }} dir="rtl">
                  تُقدم "أدفرتيكا" خدمة فريدة فعالة تنافسية التكلفة بهدف تقديم
                  حلول رقمية بخاصيات مبتكرة في مجال النشر الرقمي. نقوم بتعديل
                  وأقلمة هذه الحلول المُبتكرة لتتمحور حول العميل وتواكب
                  الاحتياجات والمتطلبات التسويقية للعملاء وذلك للوصول لجماهيرهم
                  المستهدفة من الزبائن من خلال النشرات الإعلانية المختلفة
                  كالجرائد والمجلات والنشرات وباستعمال آخر ما توصلت إليه التقنية
                  الرقمية.
                </p>
                <h5 className="ar1" style={{ textAlign: "right" }} dir="rtl">
                  لماذا النشر معنا؟
                </h5>
                <p className="ar2" style={{ textAlign: "right" }} dir="rtl">
                  يحرص فريق عملنا ذو الإختصاص والخبرة على إكمال وإكتمال وتسليم
                  أي مشروع موكل إليه بجودة عالية، توازياً مع تلبية إحتياجات
                  وتطلعات العملاء وإثراء أهدافهم المرجوة. فمن المحتمل أن يتطلب
                  الأمر إنشاء وإنتاج محتوى خاص بذلك، عمل تصميم مبتكر، توزيع
                  لعمليات الإنتاج، القيام بأعمال إنتاج تخص شبكات التواصل
                  الإجتماعي وإجراء عمليات تقييم لنتائج الحملات الدعائية
                  والإعلانية والإشهارية المختلفة.
                </p>
                <p className="ar2" style={{ textAlign: "right" }} dir="rtl">
                  خدمتنا مُجهزة بآخر ما توصلت له التطبيقات التقنية المختلفة
                  (المُشَغَّلة من قبل خدمة غوغل للتحليلات البيانية: ’غوغل
                  آناليتكس‘) ومُزودة بأكثر تلك التطبيقات تقدماً وتميزاً، لتُقدم
                  نتائج محل ثقة ومُعتمد عليها في الاحتياجات المختلفة، وذلك من
                  خلال: عمليات تحديد كَم الحركة المرورية لما يجري على الانترنت،
                  عدد المشاهدات ومعدلاتها ومصادر الأصل من منصات تلك الحركات
                  المرورية مثل: البريد الألكتروني، واتساب، فيسبوك، تويتر،
                  لينكندإن، إنستغرام.
                </p>
              </Col>

              <Col xs={12} md={5} lg={5} className="p-5">
                <img src={DigitalPublishing} className="d-block w-100" />

                <Button
                  className="btn btn-light ar1"
                  style={{
                    borderWidth: 2,
                    background: "none",
                    color: "#96153A",
                    borderColor: "#96153a",
                    marginTop: 30,
                  }}
                  onClick={() => window.open("http://digital.advertiqa.com/")}
                >
                  الدعم العابر للمنصات الرقمية
                </Button>
              </Col>
            </Row>
          )}
        </center>
      </div>
      <div className="container mt-5">
        {ln == "en" ? (
          <Row>
            <Col
              xs={12}
              md={6}
              lg={6}
              className="p-4"
              data-aos="fade-right"
              data-aos-once="true"
            >
              <img src={TouchingPhoto} className="w-100" />

              <br />

              <center>
                <h4 className="mt-3 hb1">Public Relations</h4>

                <Link to={"/Public_Relation_Profile"}>
                  <button
                    className="btn en2"
                    style={{
                      borderStyle: "solid",
                      borderWidth: 2,
                      borderColor: "#056292",
                      color: "#056292",
                      borderRadius: 10,
                    }}
                  >
                    VIEW MORE
                  </button>
                </Link>
              </center>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={6}
              className="p-4"
              data-aos="fade-left"
              data-aos-once="true"
            >
              <img src={MobilePhoto} className="w-100" />
              <br />
              <center>
                <h4 className=" mt-3 hb1">Social Media Marketing</h4>

                <Link to={"/Social_Media_Marketing"}>
                  <button
                    className="btn en2"
                    style={{
                      borderStyle: "solid",
                      borderWidth: 2,
                      borderColor: "#056292",
                      color: "#056292",
                      borderRadius: 10,
                    }}
                  >
                    VIEW MORE
                  </button>
                </Link>
              </center>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col
              xs={12}
              md={6}
              lg={6}
              className="p-4"
              data-aos="fade-right"
              data-aos-once="true"
            >
              <img src={MobilePhoto} className="w-100" />
              <br />
              <center>
                <h4 className="hb1 ar1 mt-3">
                  التسويق عبر مواقع التواصل الاجتماعي
                </h4>

                <Link to={"/Social_Media_Marketing"}>
                  <button
                    className="btn ar1"
                    style={{
                      borderStyle: "solid",
                      borderWidth: 2,
                      borderColor: "#056292",
                      color: "#056292",
                      borderRadius: 10,
                    }}
                  >
                    شاهد المزيد
                  </button>
                </Link>
              </center>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={6}
              className="p-4"
              data-aos="fade-left"
              data-aos-once="true"
            >
              <img src={TouchingPhoto} className="w-100" />

              <br />
              <center>
                <h4 className="hb1 ar1  mt-3">العلاقات العامة </h4>

                <Link to={"/Public_Relation_Profile"}>
                  <button
                    className="btn ar1"
                    style={{
                      borderStyle: "solid",
                      borderWidth: 2,
                      borderColor: "#056292",
                      color: "#056292",
                      borderRadius: 10,
                    }}
                  >
                    شاهد المزيد
                  </button>
                </Link>
              </center>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default Publishing;
