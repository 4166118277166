import React, { useState, useEffect } from 'react';
import "./styles.css";
import AdvertiqaLogo from "../../assets/AdvertiQA Logo.png";
import Title from "../../assets/TitleVERTICAL.png";
import HomeImg1 from "../../assets/Home Page.png";
import { Col, Container, Row } from "react-bootstrap";
import background from "../../assets/bg_image103.png";
import { MobileView, BrowserView } from 'react-device-detect';

const Index = (props) => {
  const { ln } = props;

  // States for the numbers
  const [count10, setCount10] = useState(0);
  const [count100, setCount100] = useState(0);
  const [count250, setCount250] = useState(0);
  const [count1500, setCount1500] = useState(0);

  // Duration for counting animation (in milliseconds)
  const animationDuration = 1000;

  // Calculate increment for each number based on duration and target value
  const increment10 = 10 / (animationDuration / 50);
  const increment100 = 100 / (animationDuration / 50);
  const increment250 = 250 / (animationDuration / 50);
  const increment1500 = 1500 / (animationDuration / 50);

  // Effects for each number
  useEffect(() => {
    const interval = setInterval(() => {
      setCount10(prevCount => {
        if (prevCount < 10) {
          return prevCount + increment10;
        } else {
          clearInterval(interval);
          return 10;
        }
      });
    }, 50);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount100(prevCount => {
        if (prevCount < 100) {
          return prevCount + increment100;
        } else {
          clearInterval(interval);
          return 100;
        }
      });
    }, 50);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount250(prevCount => {
        if (prevCount < 250) {
          return prevCount + increment250;
        } else {
          clearInterval(interval);
          return 250;
        }
      });
    }, 50);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount1500(prevCount => {
        if (prevCount < 1500) {
          return prevCount + increment1500;
        } else {
          clearInterval(interval);
          return 1500;
        }
      });
    }, 50);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="homebackground">
      <div>
        {ln === "en" ? (
          <div>
            <BrowserView>
              <Row>
                <Col xs={12} md={6} lg={7}>
                  <div className="p-5">
                    <img src={Title}  style={{ height: '110px', width:'auto' }} />
                    <p className="parabanner w-75">
                      Convert all your precious video tapes, audio 
                      cassettes & disks to digital formats to preserve 
                      your cherished memories.
                    </p>
                    <button
                      className="bn1"
                      onClick={() => window.open("https://wa.me/97451127937 ")}
                    >
                      BOOK NOW
                    </button>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={5} className="pt-5 pb-3">
                  <div>
                    <img src={HomeImg1} className="w-100" />
                  </div>
                </Col>
              </Row>
              <Row xs={12} md={12} lg={12} className="p-0 m-0">
                <div className="navbar1 p-0 m-0">
                  <div className="navstyle1 yellow">
                    <h1 className="head1">{count10 >= 10 ? '10+' : Math.floor(count10)}</h1> {/* Render count10 */}
                    <h2 className="headp2">SUPPORTED MATERIALS</h2>
                  </div>
                  <div className="navstyle1 red">
                    <h1 className="head1">{count250 >= 250 ? '250+' : Math.floor(count250)}</h1> {/* Render count250 */}
                    <h2 className="headp2">MATERIALS CONVERTED</h2>
                  </div>
                  <div className="navstyle1 green">
                    <h1 className="head1">{count1500 >= 1500 ? '1500+' : Math.floor(count1500)}</h1> {/* Render count1500 */}
                    <h2 className="headp2">HOURS OF MEMORIES RESTORED</h2>
                  </div>
                  <div className="navstyle1 blue">
                    <h1 className="head1">{count100 >= 100 ? '100%' : Math.floor(count100)}</h1>
                    <h2 className="headp2">LOCALLY OWNED</h2>
                  </div>
                </div>
              </Row>
            </BrowserView>

            <MobileView>
              <Row>
                <Col xs={12} md={6} lg={7}>
                  <div className="p-4">
                      <div>
                        <img src={Title}  style={{ height: '70px', width:'auto' }} />
                      
                        <p className="parabanner w-75">
                        Convert all your precious video tapes, audio 
                        cassettes & disks to digital formats to preserve 
                        your cherished memories.
                        </p>
                              <button
                                className="bn1"
                                onClick={() => window.open("https://wa.me/97451127937 ")}
                              >
                                BOOK NOW
                              </button>
                        
                      </div>
                  </div>
                </Col>

                  <Col xs={12} md={6} lg={5} >
                    <div>
                      <img src={HomeImg1} className="w-100 pb-3" />
                    </div>

                  </Col>
              </Row>
              <Row xs={12} md={12} lg={12} className="p-0 m-0">
                <div className="navbar1 p-0 m-0">
                  <div className="navstyle1 yellow">
                    <h1 className="head1mob">{count10 >= 10 ? '10+' : Math.floor(count10)}</h1>
                    <h2 className="headp2mob">SUPPORTED MATERIALS</h2>
                  </div>
                  <div className="navstyle1 red">
                    <h1 className="head1mob">{count250 >= 250 ? '250+' : Math.floor(count250)}</h1>
                    <h2 className="headp2mob">MATERIALS CONVERTED</h2>
                  </div>
                  <div className="navstyle1 green">
                    <h1 className="head1mob">{count1500 >= 1500 ? '1500+' : Math.floor(count1500)}</h1>
                    <h2 className="headp2mob">HOURS OF MEMORIES RESTORED</h2>
                  </div>
                  <div className="navstyle1 blue">
                    <h1 className="head1mob">{count100 >= 100 ? '100%' : Math.floor(count100)}</h1>
                    <h2 className="headp2mob">LOCALLY OWNED</h2>
                  </div>
                </div>
              </Row>
            </MobileView>

          </div>
        ) : (
              <div className="rtl">
              <BrowserView>
                <Row>
                  <Col xs={12} md={6} lg={7}>
                    <div className="p-5">
                      
                    
                      <p className="parabanner1arb">
                      خدمة حفظ الذكريات الأولى في قطر

                      </p>
                      <p className="parabannerarb">
                      للاحتفاظ بذكرياتك القيمة، قم بتحويل ذكرياتك المسجلة على أشرطة الڨيديو أو الأشرطة الصوتية (كاسيت) أو الأقراص المدمجة إلى صيغة رقمية

                      </p>
                      <div className="alignright11">
                        <button
                          className="bn1"
                          onClick={() => window.open("https://wa.me/97451127937 ")}
                        >
                          احجز الآن
                        </button>
                      </div>
                      
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={5} className="p-5 pb-3">
                    <div>
                      <img src={HomeImg1} className="w-100" />
                    </div>

                  </Col>
                  </Row>
                  <Row className="p-0 m-0">
                    <div className="navbar1 p-0 m-0">
                      <div className="navstyle1 yellow">
                        <h1 className="head1">{count10 >= 10 ? '10+' : Math.floor(count10)}</h1>
                        <h2 className="headp2">ضمان الوسائط المحولة</h2>
                      </div>
                      <div className="navstyle1 red">
                        <h1 className="head1">{count250 >= 250 ? '250+' : Math.floor(count250)}</h1>
                        <h2 className="headp2">الوسائط المحولة</h2>
                      </div>
                      <div className="navstyle1 green">
                        <h1 className="head1">{count1500 >= 1500 ? '1500+' : Math.floor(count1500)}</h1>
                        <h2 className="headp2">عدد ساعات الوسائط المحفوظة</h2>
                      </div>
                      <div className="navstyle1 blue">
                        <h1 className="head1">{count100 >= 100 ? '100%' : Math.floor(count100)}</h1>
                        <h2 className="headp2">ملكية خاصة</h2>
                      </div>
                    </div>
                  </Row>
                </BrowserView>
                
                <MobileView>
                <Row>
                  <Col xs={12} md={6} lg={7}>
                    <div className="p-5">
                      
                    
                      <p className="parabanner1arb">
                      خدمة حفظ الذكريات الأولى في قطر

                      </p>
                      <p className="parabannerarb">
                      للاحتفاظ بذكرياتك القيمة، قم بتحويل ذكرياتك المسجلة على أشرطة الڨيديو أو الأشرطة الصوتية (كاسيت) أو الأقراص المدمجة إلى صيغة رقمية

                      </p>
                      <div className="alignright11">
                        <button
                          className="bn1"
                          onClick={() => window.open("https://wa.me/97451127937 ")}
                        >
                          احجز الآن
                        </button>
                      </div>
                      
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={5}>
                    <div>
                      <img src={HomeImg1} className="w-100 pb-3" />
                    </div>

                  </Col>
                  </Row>
                  <Row className="p-0 m-0">
                    <div className="navbar1 p-0 m-0">
                      <div className="navstyle1 yellow">
                        <h1 className="head1mob">10+</h1>
                        <h2 className="headp2mob">ضمان الوسائط المحولة</h2>
                      </div>
                      <div className="navstyle1 red">
                        <h1 className="head1mob">250+</h1>
                        <h2 className="headp2mob">الوسائط المحولة</h2>
                      </div>
                      <div className="navstyle1 green">
                        <h1 className="head1mob">1500+</h1>
                        <h2 className="headp2mob">عدد ساعات الوسائط المحفوظة</h2>
                      </div>
                      <div className="navstyle1 blue">
                        <h1 className="head1mob">100%</h1>
                        <h2 className="headp2mob">ملكية خاصة</h2>
                      </div>
                    </div>
                  </Row>
                </MobileView>
    
              </div>
        )}
      </div>
    </div>
  );
};

export default Index;
