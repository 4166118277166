import React, { useState } from "react";
import logo from "../../assets/tethqar.png";
import layer1 from "../../assets/layer.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles.css";
import useStyles from "./styles.js";
import { Navbar, Nav, Container, NavDropdown, Row, Col } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

const Navbars = (props) => {
  const { ln } = props;
  const classes = useStyles();

  const [left1, setLeft1] = useState(0);

  const CarouselUI = ({ children }) => <Container>{children}</Container>;

  return (
    <div>
      {ln === "en" ? (
        <Navbar className="m-0 p-0" variant="light" expand="lg" style={{ zIndex: 4 }}>
          <Navbar.Brand href="/Tethqar" className="w-25 m-0 p-0 navstyle">
            <img src={logo} alt="TETHQAR Logo" />
          </Navbar.Brand>
          {/* Add the Layer 1 image */}
          {/* <img src={layer1} alt="Layer 1" className="layer-1-image " /> */}

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="w-100 ">
              <NavHashLink to="#tethqerabout" className="nav-link yellow m-0 navstyle">
                ABOUT
              </NavHashLink>
              <NavHashLink to="#tethqerservices" className="nav-link red m-0 navstyle">
                SERVICES
              </NavHashLink>
              <NavHashLink to="#tethqerprocess" className="nav-link green m-0 navstyle">
                PROCESS
              </NavHashLink>
              <NavHashLink to="#tethqercontact" className="nav-link blue m-0 w-100 navstyle">
                CONTACT
              </NavHashLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

      ) : (
        <Navbar className="m-0 p-0" variant="light" expand="lg" style={{ zIndex: 4 }}>

          <Navbar.Brand href="/Tethqar" className="w-25 m-0 p-0 navstyle">
            <img src={logo} alt="TETHQAR Logo" />
          </Navbar.Brand>
          {/* <Navbar.Brand href="/">
              <img src={logo} style={{ width: 150 }} />
            </Navbar.Brand> */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            
            <Nav className="w-100 ">
              <NavHashLink to="#tethqerabout" className="nav-link yellow m-0 navstyle">
              ABOUT
              </NavHashLink>
              <NavHashLink to="#tethqerservices" className="nav-link red m-0 navstyle">
              SERVICES
              </NavHashLink>
              <NavHashLink to="#tethqerprocess" className="nav-link green m-0 navstyle">
              PROCESS
              </NavHashLink>
              <NavHashLink to="#tethqercontact" className="nav-link blue m-0 w-100 navstyle">
              CONTACT
              </NavHashLink>
            </Nav>
          </Navbar.Collapse>

      </Navbar>
      )}
    </div>
  );
};

export default Navbars;
