import React from "react";
import TethqarBanner from "../../components/TethqarBanner/";
import TethqarFeatures from "../../components/Features/Tethqar";
import TethqarProducts from "../../components/TethqarProducts";
import Blogs from "../../components/Blogs";
import Navbar from "../../components/TethqarNavbar";
import AboutUs from "../../components/TethqarAboutus";
import Details from "../../components/TethqarDetails";
import ContactUs from "../../components/TethqarContactus";
import TethqarFooter from "../../components/TethqarFooter";
import Review from "../../components/TethqarCustomerReview";


const Tethqar = (props) => {
  const { ln } = props;

  return (
    <div>
      <Navbar ln={ln} />
      <TethqarBanner ln={ln} />
      <AboutUs ln={ln} />
      <TethqarProducts ln={ln} />
      <Review ln={ln} />
      <TethqarFeatures ln={ln} />
      <Details ln={ln} />
      <ContactUs ln={ln} />
      <TethqarFooter ln={ln} />
      {/* <Blogs ln={ln} /> */}
    </div>
  );
};
export default Tethqar;
