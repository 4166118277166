import React from "react";
import "./styles.css";

import FAQimage from "../../assets/FAQ Image.png";

import img1 from "../../assets/IMG-01.png";
import img2 from "../../assets/IMG-02.png";
import img3 from "../../assets/IMG-03.png";
import img4 from "../../assets/IMG-04.png";

import { Col, Container, Row } from "react-bootstrap";

const Details = (props) => {
  const { ln } = props;

  return (
    <div className="bodybg">
      <div>
        {ln == "en" ? (
            <div className="m-0 p-0">

              <img src={FAQimage} className="faqimage"/>
              <img src={img1} className="faqimage"/>
              <img src={img2} className="faqimage"/>
              <img src={img3} className="faqimage"/>
              <img src={img4} className="faqimage"/>

            </div>
          
        ) : (
          <div className="m-0 p-0 rtl">

          <img src={FAQimage} className="faqimage"/>
            <img src={img1} className="faqimage"/>
            <img src={img2} className="faqimage"/>
            <img src={img3} className="faqimage"/>
            <img src={img4} className="faqimage"/>
        </div>
        )}
      </div>
    </div>
  );
};

export default Details;
