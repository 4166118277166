import React, { useState } from "react";
import "./styles.css";
import { Navbar, Nav, Container, NavDropdown, Row, Col } from "react-bootstrap";
import Facebook from "../../assets/facebook_logo_icon.png";
import Tiktok from "../../assets/tiktok_logo_icon.png";
import Instagram from "../../assets/instagram_logo_icon.png";
import Whatsapp from "../../assets/whatsapp_logo_icon.png";
import logo from "../../assets/tethqar.png";


const Footer = (props) => {
  const { ln } = props;

  return (
    <div>
      {ln == "en" ? (
        <Navbar>
                <Col xs={7} md={6} lg={6}>
                <center>
                    <a href="https://facebook.com/100092645640738/">
                    <img src={Facebook} width={30} />
                    </a>
                    <a href="https://instagram.com/teth.qar">
                    <img src={Instagram} width={30} />
                    </a>
                    <a href="https://www.tiktok.com/@teth.qar">
                    <img src={Tiktok} width={30} />
                    </a>
                    <a href="https://wa.me/97451127937">
                    <img src={Whatsapp} width={30} />
                    </a>
                  </center>
                </Col>

                <Col xs={5} md={6} lg={6}>
                  <center>
                  <a className="navbar-brand footertethqar" href="/Tethqar">
                    <img src={logo} alt="TETHQAR Logo" style={{ width: '150px' }} />
                  </a>
                  </center>
                </Col>


        </Navbar>
      ) : (
        <Navbar className="rtl">
            


      

            <Col xs={7} md={6} lg={6}>
                  <center>
                  <a href="https://facebook.com/100092645640738/">
                    <img src={Facebook} width={30} />
                    </a>
                    <a href="https://instagram.com/teth.qar">
                    <img src={Instagram} width={30} />
                    </a>
                    <a href="https://www.tiktok.com/@teth.qar">
                    <img src={Tiktok} width={30} />
                    </a>
                    <a href="https://wa.me/97451127937">
                    <img src={Whatsapp} width={30} />
                    </a>
                  </center>
                </Col>


                <Col xs={5} md={6} lg={6}>
                  <center>
                  <a className="navbar-brand footertethqar" href="/Tethqar">
                    <img src={logo} alt="TETHQAR Logo" style={{ width: '150px' }} />
                  </a>
                  </center>
                </Col>


        </Navbar>
      )}
    </div>
  );
};

export default Footer;
