import React from "react";
import "./styles.css";
import TethqarLogo from "../../assets/fugi_betamax.png";
import Reviewimage from "../../assets/Reviews.png";
import Harddrive from "../../assets/Hard Drive Reviews.png";
import AboutusSticker from "../../assets/About Us Stickers.png";
import { Col, Container, Row } from "react-bootstrap";
import { MobileView, BrowserView } from 'react-device-detect';

const Review = (props) => {
  const { ln } = props;
  return (
    <div className="reviewbackground p-0">
        {ln == "en" ? (
          <div>
          <BrowserView>
              <h1 className="reviewhead p-5">CUSTOMER <br/>REVIEWS</h1>
                <Row>
                  <Col xs={4} md={4} lg={4}>
                    <img src={Harddrive} className="w-100"/>
                  </Col>
                  <Col xs={8} md={7} lg={8}>
                    <img src={Reviewimage} className=" w-100"/>
                  </Col>
                  <p className="reviewtext">Real customer feedack.</p>
                </Row>


          </BrowserView>
          <MobileView>

                      <h1 className="reviewheadmob p-2">CUSTOMER <br/>REVIEWS</h1>
                      <Row>
                          <Col xs={4} md={4} lg={4}>
                            <img src={Harddrive} className="w-100"/>
                          </Col>
                          <Col xs={8} md={7} lg={8}>
                            <img src={Reviewimage} className=" w-100"/>
                          </Col>
                          <p className="reviewtext">Real customer feedack.</p>
                      </Row>


          </MobileView>
          </div>
        ) : (

              <div>
                <BrowserView>
    
                  <h1 className="reviewhead p-5 rtl"> آراء العملاء</h1>
                <Row>
                  <Col xs={4} md={4} lg={4}>
                    <img src={Harddrive} className="w-100"/>
                  </Col>
                  <Col xs={8} md={7} lg={8}>
                    <img src={Reviewimage} className=" w-100"/>
                  </Col>
                  <p className="reviewtext">آراء العملاء بكل صدق</p>
                  </Row>

                </BrowserView>
                <MobileView>
                    
                    <h1 className="reviewheadmob rtl"> آراء العملاء</h1>
                  <Row>
                    <Col xs={4} md={4} lg={4}>
                      <img src={Harddrive} className="w-100"/>
                    </Col>
                    <Col xs={8} md={7} lg={8}>
                      <img src={Reviewimage} className=" w-100"/>
                    </Col>
                    <p className="reviewtext">آراء العملاء بكل صدق</p>
                    </Row>

                </MobileView>
    
    
    
              </div>
        )}
      </div>
  );
};

export default Review;
