import React from "react";
import NumerosBanner from "../../components/NumerosBanner/";
import NumeroFeatures from "../../components/Features/Numero";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footer";
import Contact from "../../components/Contact";

const Numero = (props) => {
  const { ln } = props;

  return (
    <div>
      <Navbar ln={ln} />
      <NumerosBanner ln={ln} />
      <NumeroFeatures ln={ln} />
      <Contact ln={ln} />
      <Footer ln={ln} />
    </div>
  );
};
export default Numero;
