import React from "react";
import "./styles.css";
import NumeroLogo from "../../assets/numero-2.png";
import AppStore from "../../assets/app-store.png";
import PlayStore from "../../assets/google-play.png";
import { Col, Row } from "react-bootstrap";
import useStyles from "./styles";
import QrCode from "../../assets/qr-numero-2.png";

const NumerosBanner = (props) => {
  const { ln } = props;
  const classes = useStyles();
  return (
    <div className={classes.banner}>
      <div className="container">
        {ln == "en" ? (
          <Row>
            <Col xs={12} md={3} lg={3} className="p-5">
              <img src={NumeroLogo} className="w-100" />
            </Col>
            <Col xs={12} md={7} lg={7}>
              <div className="p-4 pt-5">
                <h1 className={classes.head1}>Numero eSim</h1>
                <p className="para">
                  Numero eSIM is a unique telecom mobile App that converts
                  users' smart mobile to two mobiles or more. It adds a second
                  virtual mobile on the same mobile device. This virtual mobile
                  is fully featured. The App doesn't need a physical SIM card
                  and it will not affect the local SIM card.
                </p>
              </div>

              <Row>
                <Col xs={6} md={3} lg={3}>
                  <a href="https://apps.apple.com/us/app/numero-esim-2nd-phone-number/id1249825694">
                    <img src={AppStore} className="d-block w-100" />
                  </a>
                </Col>

                <Col xs={6} md={3} lg={3}>
                  <a href="https://play.google.com/store/apps/details?id=com.esim.numero">
                    <img src={PlayStore} className="d-block w-100" />
                  </a>
                </Col>

                <Col xs={12} md={3} lg={3}>
                  <button
                    className="btn bn mt-4"
                    style={{
                      borderWidth: 2,
                      borderColor: "#056292",
                      color: "#056292",
                      fontWeight: "bold",
                      height: 42,
                      fontSize: 13,
                    }}
                    onClick={() =>
                      window.open(
                        "https://affiliate.numeroesim.com/en/download-app/03034d78-152a-47d7-be1f-a9c97ce7c415"
                      )
                    }
                  >
                    VISIT WEBSITE
                  </button>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={2} lg={2}>
              <img src={QrCode} className="d-block w-100 mt-5" />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={12} md={2} lg={2}>
              <img src={QrCode} className="d-block w-100 mt-5" />
            </Col>
            <Col xs={12} md={7} lg={7}>
              <div className="p-4 pt-5">
                <h1 className="ar1 hb1" dir="rtl">
                  نُوميرو إيي. سِيم
                </h1>

                <p className="para ar2" dir="rtl">
                  تطبيق إتصال متحرك فريد من نوعه، قادرٌ على تحويل هواتف
                  المستخدمين الذكية الشخصية لهاتفين أو أكثر. يتميز التطبيق
                  بقدرته على إضافة هاتف إفتراضي متحرك ثانٍ على نفس الجهاز
                  المتحرك الأصلي. يتميز الهاتف الإفتراضي بكامل الخدمات، حيث لا
                  يحتاج إلى شريحة هاتفية أخرى، ولن يؤثر على عمل الشريحة الموجودة
                  مسبقاً في الجهاز.
                </p>
              </div>

              <br />
              <Row>
                <Col xs={12} md={3} lg={3}>
                  <button
                    className="btn bn ar2"
                    style={{
                      borderWidth: 2,
                      borderColor: "#056292",
                      color: "#056292",
                      fontWeight: "bold",
                      height: 42,
                      fontSize: 13,
                      marginTop: 26,
                    }}
                    onClick={() =>
                      window.open(
                        "https://affiliate.numeroesim.com/en/download-app/03034d78-152a-47d7-be1f-a9c97ce7c415"
                      )
                    }
                  >
                    الموقع الإلكتروني
                  </button>
                </Col>

                <Col xs={6} md={3} lg={3}>
                  <a href="https://play.google.com/store/apps/details?id=com.esim.numero">
                    <img src={PlayStore} className="d-block w-100" />
                  </a>
                </Col>

                <Col xs={6} md={3} lg={3}>
                  <a href="https://apps.apple.com/us/app/numero-esim-2nd-phone-number/id1249825694">
                    <img src={AppStore} className="d-block w-100" />
                  </a>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
            </Col>
            <Col xs={12} md={3} lg={3} className="p-5">
              <img src={NumeroLogo} className="w-100" />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default NumerosBanner;
