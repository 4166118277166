import React, { Component } from "react";
import IconBar from "../../components/IconBar";

import Carousel from "../../components/Carousel";
import About from "../../components/About";
import Mission from "../../components/Mission";
import Values from "../../components/values";

import Clients from "../../components/clients";
import Services from "../../components/services";
import Products from "../../components/Products";
import Publishing from "../../components/Publishing";
import Event from "../../components/Event";
import Projects from "../../components/Projects";

import Relations from "../../components/Relations";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footer";
import Contact from "../../components/Contact";

const Home = (props) => {
  const { ln } = props;

  return (
    <div>
      <Navbar ln={ln} />
      <Carousel ln={ln} />

      <About ln={ln} />
      <Mission ln={ln} />
      <Values ln={ln} />
      <Services ln={ln} />
      <Products ln={ln} />
      <Publishing ln={ln} />
      <Clients ln={ln} />
      <Projects ln={ln} />
      <Event ln={ln} />
      <Contact ln={ln} />
      <Footer ln={ln} />
    </div>
  );
};

export default Home;
