import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import useStyles from "./styles";
import Aos from "aos";
import Globe from "../../assets/globe.jpg";
import "./styles2.css";
import Icon1 from "../../assets/Advertising Icons/corporate.png";
import Icon2 from "../../assets/Advertising Icons/graphic design.png";
import Icon3 from "../../assets/Advertising Icons/photos&videos.png";
import Icon4 from "../../assets/Advertising Icons/print&digital advertising.png";

import Icon5 from "../../assets/Communication Icons/print&digital publishing.png";
import Icon6 from "../../assets/Communication Icons/event management.png";
import Icon7 from "../../assets/Communication Icons/public relation.png";
import Icon8 from "../../assets/Communication Icons/copy writing & translation.png";

import Icon9 from "../../assets/Digital Marketing Icons/mobile app development.png";
import Icon10 from "../../assets/Digital Marketing Icons/social media management.png";
import Icon11 from "../../assets/Digital Marketing Icons/website development.png";

import Slide1 from "../../assets/services/ENGLISH IMAGES/ADVERTISING.jpg";
import Slide2 from "../../assets/services/ENGLISH IMAGES/COMMUNICATIONS.jpg";
import Slide3 from "../../assets/services/ENGLISH IMAGES/MARKETING.jpg";

import Slide1AR from "../../assets/services/ARABIC IMAGES/ADVERTISING_AR.jpg";
import Slide2AR from "../../assets/services/ARABIC IMAGES/COMMUNICATIONS_AR.jpg";
import Slide3AR from "../../assets/services/ARABIC IMAGES/MARKETING_AR.jpg";

const Services = (props) => {
  const { ln } = props;
  const classes = useStyles();
  const [selected, setSelected] = useState(1);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="container-fluid mt-5 mb-5 section" id="services">
      {ln == "en" ? (
        <Row>
          <Col className={classes.section1} xs={12} lg={6} md={6}>
            <div className="h-100">
              {selected == 1 ? (
                <img src={Slide2} className={classes.sideImage} />
              ) : null}
              {selected == 2 ? (
                <img src={Slide1} className={classes.sideImage} />
              ) : null}

              {selected == 3 ? (
                <img src={Slide3} className={classes.sideImage} />
              ) : null}
            </div>
          </Col>

          <Col className={classes.section2} xs={12} lg={6} md={6}>
            {selected == 1 ? (
              <div>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon5} width={50} />

                      <h5 className="h3p1">Print & Digital Publishing</h5>
                    </center>
                    <p className="en2">
                      Through our service, we provide the traditional print
                      publishing methods and transcend to adapt to the changing
                      tastes in publishing to an online audience.
                    </p>
                  </Col>

                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon6} width={50} />

                      <h5 className="h3p1">Events Management</h5>
                    </center>

                    <p className="en2">
                      You can certainly leave your events, seminars or
                      conferences to our efficient event planners who will do
                      whatever it takes to make sure everything runs smoothly.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon7} width={50} />

                      <h5 className="h3p1">Public Relations</h5>
                    </center>

                    <p className="en2">
                      We can assist you with all your media and press-related
                      needs. Whether it’s a press release or maintaining a
                      positive relation with the press, we have it handled.
                    </p>
                  </Col>

                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon8} width={50} />

                      <h5 className="h3p1">Copywriting & Translation</h5>
                    </center>

                    <p className="en2">
                      With our talented team of writers, we will ensure that you
                      receive the best possible wording to appeal to your
                      diverse target audience in their preferred language.
                    </p>
                  </Col>
                </Row>
              </div>
            ) : null}

            {selected == 2 ? (
              <div>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon4} width={50} />

                      <h5 className="h3p1">Print & Digital Advertising</h5>
                    </center>

                    <p className="en2">
                      We will customize your advertisements to reach the
                      targeted audience demographic to increase visibility among
                      potential consumers of your product or service
                    </p>
                  </Col>

                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon1} width={50} />

                      <h5 className="h3p1">Corporate Branding</h5>
                    </center>

                    <p className="en2">
                      Promoting the brand identity to the targeted audience.
                      While Portraying a consistent image with the company’s
                      strategy, business activity, and culture
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon2} width={50} />

                      <h5 className="h3p1">Graphic Design</h5>
                    </center>
                    <p className="en2">
                      Synthesizing creative visual representations in the form
                      of images, texts, and graphics to depict the persona of a
                      corporation.
                    </p>
                  </Col>

                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon3} width={50} />

                      <h5 className="h3p1">Photography & Videography</h5>
                    </center>

                    <p className="en2">
                      Providing high end photography & videography covering
                      advertising advertisements, products & food products,
                      covering events & activities
                    </p>
                  </Col>
                </Row>
              </div>
            ) : null}

            {selected == 3 ? (
              <div>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon9} width={50} />

                      <h5 className="h3p1">Social Media Management</h5>
                    </center>

                    <p className="en2">
                      By advertising online, we will customize your
                      advertisements to reach the targeted audience demographic
                      to increase visibility among potential consumers of your
                      product or service.
                    </p>
                  </Col>

                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon11} width={50} />

                      <h5 className="h3p1">Web Development</h5>
                    </center>

                    <p className="en2">
                      Whether it’s a basic computer sized website, or a compact
                      mobile website, our team will develop exactly what you
                      need to promote your business or product.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon10} width={50} />

                      <h5 className="h3p1">Mobile App Development</h5>
                    </center>

                    <p className="en2">
                      With the rising usage of mobile applications, we will keep
                      up with the newest technologies and interfaces to ensure
                      you have an app to complement your business needs
                    </p>
                  </Col>
                  <Col xs={12} md={6} lg={6}></Col>
                </Row>
              </div>
            ) : null}

            <br />
            <Row>
              <Col xs={12} md={6} lg={6}>
              </Col>
              <Col xs={12} md={6} lg={6}>
                {selected == 1 ? (
                  <button
                    variant="light"
                    className={classes.activeBn}
                    onClick={() => setSelected(1)}
                  >
                    <span>Communication</span>
                  </button>
                ) : (
                  <button
                    variant="light"
                    className={classes.disabledBn}
                    onClick={() => setSelected(1)}
                  >
                    <span>Communication</span>
                  </button>
                )}
                <br />
                {selected == 2 ? (
                  <button
                    variant="light"
                    className={classes.activeBn}
                    onClick={() => setSelected(2)}
                  >
                    Advertising
                  </button>
                ) : (
                  <button
                    variant="light"
                    className={classes.disabledBn}
                    onClick={() => setSelected(2)}
                  >
                    Advertising
                  </button>
                )}
                <br />
                {selected == 3 ? (
                  <button
                    variant="light"
                    className={classes.activeBn}
                    onClick={() => setSelected(3)}
                  >
                    <span>Digital Marketing</span>
                  </button>
                ) : (
                  <button
                    variant="light"
                    className={classes.disabledBn}
                    onClick={() => setSelected(3)}
                  >
                    <span>Digital Marketing</span>
                  </button>
                )}
                <br />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col className={classes.section2} xs={12} lg={6} md={6}>
            {selected == 1 ? (
              <div>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon6} width={50} />
                    </center>
                    <h5 className="h3p1 ar1" dir="rtl">
                      تنظيم الفعاليات
                    </h5>

                    <p className="ar2" dir="rtl">
                      دعنا نتولى تنظيم فعالياتك ونشاطاتك ومحاضراتك وندواتك
                      بالطريقة المثلى، حيث نوكل هذه المهام لطاقم عملنا المحترف
                      والمتخصص في هذه المجالات .
                    </p>
                  </Col>

                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon5} width={50} />
                    </center>
                    <h5 className="h3p1 ar1" dir="rtl">
                      الطباعة والنشر الرقمي
                    </h5>

                    <p className="ar2" dir="rtl">
                      نقوم بتوفير وتقديم خدماتنا في الطباعة التقليدية وكذلك
                      النشر الرقمي والتأقلم ومواكبة التطورات والمتغيرات الحاصلة
                      في مجال النشر لجمهور متنوع عريض شغوف متواجد على شبكات
                      إلانترنت .
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon8} width={50} />
                    </center>
                    <h5 className="h3p1 ar1" dir="rtl">
                      التأليف والترجمة والتدقيق التحريري
                    </h5>

                    <p className="ar2" dir="rtl">
                      نضمن الحصول على أفضل معايير ومقاييس ومستويات الجودة في
                      الإدارة الشاملة لمحتوى رسالتك التي تريد إطلاقها وتود أن
                      تخاطب بها جمهورك من العملاء والزبائن، وذلك من خلال فريق
                      عملنا من الكُتاب والمُحررين المختصين والمهنيين المحترفين
                      في المجال.
                    </p>
                  </Col>

                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon7} width={50} />
                    </center>
                    <h5 className="h3p1 ar1" dir="rtl">
                      العلاقات العامة
                    </h5>

                    <p className="ar2" dir="rtl">
                      نعمل على تلبية كل احتياجاتك المتعلقة بالصحافة والإعلام
                      بالحضور والتواجد تحت الأضواء ' سواء تعلق الأمر ببيان صحفي
                      أو الحفاظ على علاقات جيدة مع وسائل الإعلام المختلفة.
                    </p>
                  </Col>
                </Row>
              </div>
            ) : null}

            {selected == 2 ? (
              <div>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon4} width={50} />
                    </center>
                    <h5 className="h3p1 ar1" dir="rtl">
                      الطباعة والإعلان الرقمي
                    </h5>

                    <p className="ar2" dir="rtl">
                      سنقوم بتعديل ومواكبة إحتياجاتك الإعلانية لتصل لأهدافها
                      المرجوة للعملاء والزبائن المستهدفين، وذلك لإبراز شركتك
                      وعلامتك التجارية ونشاطاتك المختلفة بشكل لافت ومتميز من بين
                      المنافسين.
                    </p>
                  </Col>

                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon1} width={50} />
                    </center>
                    <h5 className="h3p1 ar1" dir="rtl">
                      الهويات المؤسسية والعلامات التجارية
                    </h5>

                    <p className="ar2" dir="rtl">
                      نُساعدك في التسويق لعلامتك التجارية، وذلك بإجراءنا لعمليات
                      وخطط التنسيق المناسبة بين صورة الشركة واستراتيجياتها
                      ونشاطاتها التجارية ورؤيتها ورسالتها وقيَمُها .
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon2} width={50} />
                    </center>
                    <h5 className="h3p1 ar1" dir="rtl">
                      التصميم الغرافيكي
                    </h5>

                    <p className="ar2" dir="rtl">
                      نختص بجَمْع وتجميع التمثيلات والرسومات البصرية لجهة عاملة
                      ما، وتركيبها و تشكيلها بطرق مبتكرة إبداعية تعكس شخصية
                      ورؤية ورسالة وهدف تلك الجهة العاملة.
                    </p>
                  </Col>

                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon3} width={50} />
                    </center>
                    <h5 className="h3p1 ar1" dir="rtl">
                      التصوير الفوتوغرافي والمرئي
                    </h5>

                    <p className="ar2" dir="rtl">
                      نقوم بتوفير وتزويد جميع الجهات العاملة بكل احتياجاتها من
                      آخر صيحات أنواع التصوير المختلفة عالية الجودة، والتكفل بكل
                      أنواع التغطيات الصحفية والإعلامية والتسويقية والترويجية
                      والتوعوية، للإعلانات والمنتجات والخدمات والفعاليات
                      والنشاطات المختلفة .
                    </p>
                  </Col>
                </Row>
              </div>
            ) : null}

            {selected == 3 ? (
              <div>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon9} width={50} />
                    </center>
                    <h5 className="h3p1 ar1" dir="rtl">
                      إدارة شبكات التواصل الإجتماعي
                    </h5>

                    <p className="ar2" dir="rtl">
                      نُلبي بِي ونواكب إحتياجاتك الإعلانية لكي تصل إلى جمهورك
                      المُستهدف بقطاعاته السكانية الديموغرافية المختلفة، من خلال
                      التفاعلات والمشاركات بأشكالها المتعددة وذلك لإبراز علامتك
                      التجارية: المُنتج أو السلعة والخدمة.
                    </p>
                  </Col>

                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon11} width={50} />
                    </center>
                    <h5 className="h3p1 ar1" dir="rtl">
                      المواقع الألكترونية
                    </h5>

                    <p className="ar2" dir="rtl">
                      سواء تعَلَّق الأمر بموقع الكتروني ثابت أو متحرك المحتوى،
                      فإن فريق عملنا سيقوم بتطوير كل ما تحتاجه بالضبط للترويج
                      والتسويق لعلامتك التجارية بأفضل الطرق الحركية في
                      الإستجابة.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col xs={12} md={6} lg={6}>
                    <center>
                      <img src={Icon10} width={50} />
                    </center>
                    <h5 className="h3p1 ar1" dir="rtl">
                      تطبيقات الهاتف المتحرك
                    </h5>

                    <p className="ar2" dir="rtl">
                      نعمل بلا كلل، بسبب النمو المتسارع في استعمال تطبيقات
                      الهواتف الذكية، على متابعة ومواكبة آخر مُستجدات ما توصلت
                      التقنيات العالمية المتعلقة بالتواصل والتواصل بالهواتف
                      والحواسيب الآلية وذلك لتلبية كل إحتياجات ومتطلبات وتطلعات
                      زبائننا وعملاءنا.
                    </p>
                  </Col>
                </Row>
              </div>
            ) : null}
            <Row dir="rtl">
              <Col xs={12} md={6} lg={6}>
                <br />

                {selected == 1 ? (
                  <button
                    variant="light"
                    className={classes.activeBn2}
                    onClick={() => setSelected(1)}
                  >
                    <span className="ar1">التواصل</span>
                  </button>
                ) : (
                  <button
                    variant="light"
                    className={classes.disabledBn}
                    onClick={() => setSelected(1)}
                  >
                    <span className="ar1">التواصل</span>
                  </button>
                )}
                <br />
                {selected == 2 ? (
                  <button
                    variant="light"
                    className={classes.activeBn2}
                    onClick={() => setSelected(2)}
                  >
                    <span className="ar1">الإعلان</span>
                  </button>
                ) : (
                  <button
                    variant="light"
                    className={classes.disabledBn}
                    onClick={() => setSelected(2)}
                  >
                    <span className="ar1">الإعلان</span>
                  </button>
                )}
                <br />
                {selected == 3 ? (
                  <button
                    variant="light"
                    className={classes.activeBn2}
                    onClick={() => setSelected(3)}
                  >
                    <span className="ar1">التسويق الرقمي</span>
                  </button>
                ) : (
                  <button
                    variant="light"
                    className={classes.disabledBn}
                    onClick={() => setSelected(3)}
                  >
                    <span className="ar1">التسويق الرقمي</span>
                  </button>
                )}
                <br />
              </Col>
              <Col xs={12} md={6} lg={6}>
              </Col>
            </Row>
          </Col>
          <Col className={classes.section1} xs={12} lg={6} md={6}>
            <div className="h-100" dir="rtl">
              {selected == 1 ? (
                <img src={Slide2AR} className={classes.sideImage} />
              ) : null}
              {selected == 2 ? (
                <img src={Slide1AR} className={classes.sideImage} />
              ) : null}

              {selected == 3 ? (
                <img src={Slide3AR} className={classes.sideImage} />
              ) : null}


            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Services;
