import React from "react";
import SocialBanner from "../../components/SocialBanner";
import Marketing from "../../components/SocialMedias/Marketing";
import SocialMarketing from "../../components/SocialMedias/Marketing";
import Grid from "../../components/SocialMedias/Grid";
import Usage from "../../components/SocialMedias/Usage";
import Services from "../../components/SocialMedias/Services";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footer";
import Contact from "../../components/Contact";


const SocialMedia = (props) => {
  const { ln } = props;

  return (
    <div>
      <Navbar ln={ln} />
      <SocialBanner ln={ln} />
      <Marketing ln={ln} />
      <Grid ln={ln} />
      <Usage ln={ln} />
      <Services ln={ln} />
      <Contact ln={ln} />
      <Footer ln={ln} />
    </div>
  );
};

export default SocialMedia;
